import styled from '@emotion/styled';
import { Download } from 'react-feather';
import { useContext, useState } from 'react';
import { useFloating, useHover, useInteractions } from '@floating-ui/react';
import { PopperMenuItem } from '@/components/Popper';
import { useAsyncMutationDownloader } from '@containers/QueryDownloader';
import { ReportFormat } from '@/enums';
import * as $api from '@api/projects.reports';
import type { Chat } from '@/brand-insights/types';
import { ChartInfoContext } from './context';
import { BaseMessageButton } from './Message.Button';

type Props = {
  item: Chat.Query;
  className?: string;
  label?: string;
  onClick: () => void;
};

const useDownloadChartImage = (queryId: number, title: string) => {
  return useAsyncMutationDownloader({
    mutationFn: () => {
      return $api.downloadChartImage({
        queryId: queryId,
        title: title,
      });
    },
  });
};

const useDownloadChartPptx = (queryId: number, title: string) => {
  return useAsyncMutationDownloader({
    mutationFn: () => {
      return $api.downloadChartPptx({
        queryId: queryId,
        title: title,
      });
    },
  });
};

const useDownloadChartCsv = (queryId: number, title: string) => {
  return useAsyncMutationDownloader({
    mutationFn: () => {
      return $api.downloadChartCsv({
        queryId: queryId,
        title: title,
      });
    },
  });
};

const size = 16;

export const DownloadButton = ({ item, className, label = 'Download', onClick }: Props) => {
  const [chart, setChart] = useContext(ChartInfoContext);
  const [isOpen, setIsOpen] = useState(false);
  const title = chart ? chart.chart.title : 'chart';
  const downloadPptxMutation = useDownloadChartPptx(item.id, title);
  const downloadImageMutation = useDownloadChartImage(item.id, title);
  const downloadCsvMutation = useDownloadChartCsv(item.id, title);

  const { mutate: downloadPptx } = downloadPptxMutation;
  const { mutate: downloadImage } = downloadImageMutation;
  const { mutate: downloadCsv } = downloadCsvMutation;

  const { context, refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  const handlePptxDownload = () => {
    downloadPptx({
      meta: {
        format: ReportFormat.PowerPoint,
        name: title,
      },
    });
  };

  const handleImageDownload = () => {
    downloadImage({
      meta: {
        format: ReportFormat.Img,
        name: title,
      },
    });
  };

  const handleCsvDownload = () => {
    downloadCsv({
      meta: {
        format: ReportFormat.Csv,
        name: title,
      },
    });
  };

  return (
    <div
ref={refs.setReference}
{...getReferenceProps()}>
      <BaseMessageButton
        className={className}>
        <StyledIcon size={size} />
        <ButtonCopy>{label}</ButtonCopy>
      </BaseMessageButton>

      {isOpen && (
        <PopperMenu
ref={refs.setFloating}
style={floatingStyles}
{...getFloatingProps()}>
          <PopperMenuItem key="export_ppt" onClick={handlePptxDownload}>Export as PPT</PopperMenuItem>
          <PopperMenuItem
key="save_img"
onClick={handleImageDownload}> Save as Image
          </PopperMenuItem>
          <PopperMenuItem key="export_csv" onClick={handleCsvDownload}>Export as CSV</PopperMenuItem>
        </PopperMenu>
      )}
    </div>
  );
};

const ButtonCopy = styled.div({
  marginLeft: 5,
});

const StyledIcon = styled(Download)(({ theme }) => ({
  fontSize: '18px',
  display: 'flex',
  flexShrink: 0,
}));

const PopperMenu = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: 8,
  borderRadius: 4,
  boxShadow: `0 2px 6px 2px rgba(60, 64, 67, .15)`,
  zIndex: 4,
  textAlign: 'left',
}));