import { useCallback } from 'react';
import { useInvalidateFilesQueries, useInvalidateFilePreviewQueries } from '@utils/api';
import type { WorkspaceFile } from '@/types';
import { useRenameFile } from './hooks';
import { RenameFileContext } from './Context';

type Props =
  IWorkspaceId &
  { file: WorkspaceFile } &
  ChildrenProps;

export const RenameFileContainer = (props: Props) => {

  const renameFile = useRenameFile();
  const invalidateFilesQueries = useInvalidateFilesQueries();
  const invalidateFilePreviewQueries = useInvalidateFilePreviewQueries();

  const handleRename = useCallback((name: string) => {
    return renameFile({
      fileId: props.file.id,
      name,
      workspaceId: props.workspaceId,
    })
      .then(() => {
        invalidateFilePreviewQueries({ fileId: props.file.id });
        invalidateFilesQueries();
      });
  }, [
    invalidateFilesQueries,
    invalidateFilePreviewQueries,
    renameFile,
    props.file.id,
    props.workspaceId,
  ]);

  const value = {
    file: props.file,
    rename: handleRename,
  };

  return (
    <RenameFileContext.Provider value={value}>
      {props.children}
    </RenameFileContext.Provider>
  );
};

export default RenameFileContainer;