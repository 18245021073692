import { createId } from '@paralleldrive/cuid2';
import { ChatContextType } from '@/brand-insights/enums';
import type { BrandInsightsPageContext } from '@/brand-insights/types';
import { ProjectType } from '@/enums';
import type { ChatSessionState, ChatState } from './interfaces';
import type { ChatSessionManagerState } from './interfaces.chat-session-manager';

type GenerateEmptyChatSessionStateData = {
  context?: BrandInsightsPageContext.Item;
};

export function generateEmptyChatSessionState({ context }: GenerateEmptyChatSessionStateData = { context: { type: ChatContextType.Global } }): ChatSessionState.State {
  return {
    chatInstance: {
      createdOn: new Date(),
      id: null,
      identifier: createId(),
      name: '',
      currentQueryIdentifier: null,
      context,
      shared: null,
    },
    input: '',
    selectedQueryIdentifier: null,
    queries: {},
    filters: generateEmptyChatSessionFiltersState({ context }),
    fileUploads: [],
    pendingFindQuotes: false,
    segmentations: [],
  };
}

type GenerateEmptyChatSessionFiltersState = {
  context?: BrandInsightsPageContext.Item;
};

export function generateEmptyChatSessionFiltersState({ context }: GenerateEmptyChatSessionFiltersState): ChatSessionState.State['filters'] {
  return {
    sources: getDefaultChatSourceFilterState({ context }),
    minDocumentDate: null,
    tags: [],
    projects: [],
  };
}

export function getChatSourceFilterOptions({ context }: GenerateEmptyChatSessionFiltersState) {
  const base = [
    { id: 'transcripts', name: 'Transcripts' },
    { id: 'presentations', name: 'Presentations' },
    { id: 'text', name: 'Text' },
  ];

  switch (context?.type) {
    case ChatContextType.Project: {
      const r = Array.from(base);

      if (context.metadata.projectType === ProjectType.Survey) {
        r.unshift({ id: 'surveys', name: 'Surveys' });
        r.unshift({ id: 'survey_analyses', name: 'Survey Analyses' });
      }
      return r;
    }

    case ChatContextType.Brand: {
      return base;//.filter(x => x.id !== 'transcripts');
    }
    case ChatContextType.Global:
    case ChatContextType.Folder: {
      return Array.from(base);
    }
    default: {
      return [];
    }
  }
}

export function getDefaultChatSourceFilterState({ context }: GenerateEmptyChatSessionFiltersState) {
  const options = getChatSourceFilterOptions({ context });
  switch (context?.type) {
    case ChatContextType.Brand: {
      return options.filter(x => x.id !== 'transcripts');
    }
    default: {
      return options;
    }
  }
}

export function generateEmptyChatSessionManagerState(): ChatSessionManagerState.State {
  return {
    currentChatInstanceIdentifier: null,
    nextChatInstanceIdentifier: null,
    status: 'uninitialized',
  };
}

type GenerateEmptyChatStateData = GenerateEmptyChatSessionStateData;

export function generateEmptyChatState(data?: GenerateEmptyChatStateData): ChatState.State {
  return {
    history: {
      manager: {
        mode: 'page',
        cursor: null,
        loading: false,
        hasMore: false,
        initialized: false,
      },
      items: [],
      shared: [],
    },
    session: generateEmptyChatSessionState(data),
    sessionManager: generateEmptyChatSessionManagerState(),
  };
}