import { useCallback } from 'react';
import * as $api from '@api';
import Toast from '@/components/Toast';
import type { RenameTranscriptParams } from '../interfaces';

export const useRenameTranscript = () => {

  const renameTranscript = useCallback((data: RenameTranscriptParams) => {
    return $api.transcripts.renameTranscript({
      transcriptId: data.transcriptId,
      name: data.name,
    }).then(() => {
      Toast.alert({
        title: 'Transcript Renamed',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, []);

  return renameTranscript;
};

export default useRenameTranscript;