// eslint-disable-next-line import/newline-after-import
require('./sentry');
import './wdyr';
import './types/index';
import './ascii';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { history } from '@services/history';
import store from '@store';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

serviceWorkerRegistration.unregister();