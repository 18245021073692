import { createContext } from 'react';
import type { Chat } from '@/brand-insights/types';
import type { Context } from './interfaces';

export const SubmitMessageContext = createContext<Context.SubmitMessage>(undefined);
export const ChatPageContextDataContext = createContext<Context.ChatPageContextData>(undefined);
export const DispatchPageContextDataContext = createContext<Context.DispatchPageContextData>(undefined);
export const AuthorContext = createContext<Context.Author>(undefined);
export const RegenerateMessageContext = createContext<Context.RegenerateMessage>(undefined);
export const VariantMessageContext = createContext<Context.VariantMessage>(undefined);
export const UploadFilesContext = createContext<Context.UploadFiles>(undefined);
export const IsPreparingUploadContext = createContext<boolean>(undefined);

export const ChatSessionFiltersContext = createContext<Chat.Filters>(undefined);
export const ChatSessionInputContext = createContext<string>(undefined);
export const ChatSessionQueriesContext = createContext<Record<string, Chat.Query>>(undefined);
export const ChatSessionFileUploadsContext = createContext<Chat.FileUpload[]>(undefined);
export const ChatSessionSegmentationsContext = createContext<Chat.Segmentation.Segmentation[]>(undefined);
export const ActiveChatSessionContext = createContext<Context.ActiveChatSession>(undefined);
export const ChatSessionSelectedQueryContext = createContext<Chat.Query>(undefined);
export const ChatSessionPendingFindQuotesContext = createContext<boolean>(undefined);

export const ChatHintsContext = createContext<Chat.QueryHint.Item[]>([]);
export const ChatHintsLoadingContext = createContext<boolean>(undefined);

export const SubmitActionContext = createContext<Context.SubmitAction>(undefined);

export const ChatPermissionsContext = createContext<Context.ChatPermissions>(undefined);

export const MessagesListRefContext = createContext<Context.MessagesListRef>(undefined);

export const ChatSettingsToggleContext = createContext<Context.ChatSettingsToggle>(undefined);
export const MessageCitationsToggleContext = createContext<Context.MessageCitationsToggle>(undefined);
export const OpenSnippetContext = createContext<Context.OpenSnippet>(undefined);
export const ClickCitationContext = createContext<Context.ClickCitation>(undefined);
export const AssignCitationRefContext = createContext<Context.AssignCitationRef>(undefined);
export const CitationsContext = createContext<Chat.Citation[]>(undefined);
export const OpenCitationContext = createContext<string>(undefined);
export const SetOpenCitationContext = createContext<Context.SetOpenCitation>(undefined);

export const ChatHasAccessContext = createContext<boolean>(undefined);
export const LegendCollapseToggleContext = createContext<Context.LegendCollapseToggle>(undefined);
export const ChartRefContext = createContext<Context.ChartRef>(undefined);
export const ChartInfoContext = createContext<Context.ChartInfo>(undefined);