import { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@/brand-insights/api';
import { ChatContextType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { ActiveChatSessionContext, ChatHasAccessContext } from './context';

type Props = ChildrenProps;

export const ChatGuard = (props: Props) => {

  const chat = useContext(ActiveChatSessionContext);

  const isChatShared = useMemo(() => {
    return !!chat.shared;
  }, [chat.shared]);

  const query = useQuery({
    queryKey: ['chat:access-check', chat.context, isChatShared],
    queryFn: v => {
      const isShared = v.queryKey[2] as boolean;
      if (isShared) return true;

      const context = v.queryKey[1] as Chat.InstanceContextWithMetadata;

      if (!context) return true;

      if (context.type === ChatContextType.Global) return true;

      return $api.getChatContextAccess({
        context: v.queryKey[1] as Chat.InstanceContextWithMetadata,
      }).then(r => r.value);
    },
    placeholderData: true,
  });

  return (
    <ChatHasAccessContext.Provider value={true}>
      {props.children}
    </ChatHasAccessContext.Provider>
  );
};