import { useCallback } from 'react';
import { useInvalidateFilesQueries, useInvalidateCallTranscriptQuery, useInvalidateWorkspaceTranscriptQuery } from '@utils/api';
import { RenameTranscriptContext } from './context';
import { useRenameTranscript } from './hooks/useRenameTranscript';

type Props =
  { transcriptId: number;
    originalName: string;
    callId?: number;
  } &
  ChildrenProps;

export const RenameTranscriptContainer = (props: Props) => {
  const renameTranscript = useRenameTranscript();
  const invalidateFilesQueries = useInvalidateFilesQueries();
  const invalidateCallTranscriptQuery = useInvalidateCallTranscriptQuery();
  const invalidateWorkspaceTranscriptQuery = useInvalidateWorkspaceTranscriptQuery();

  const handleRename = useCallback((name: string) => {
    return renameTranscript({
      transcriptId: props.transcriptId,
      name,
    })
      .then(() => {
        invalidateFilesQueries();
        invalidateCallTranscriptQuery({ callId: props.callId });
        invalidateWorkspaceTranscriptQuery({ transcriptId: props.transcriptId });
      });
  }, [renameTranscript, props.transcriptId, props.callId, invalidateFilesQueries, invalidateCallTranscriptQuery, invalidateWorkspaceTranscriptQuery]);

  const value = {
    transcriptId: props.transcriptId,
    originalName: props.originalName,
    rename: handleRename,
  };

  return (
    <RenameTranscriptContext.Provider value={value}>
      {props.children}
    </RenameTranscriptContext.Provider>
  );
};