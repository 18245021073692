import { useCallback, useContext } from 'react';
import { createId } from '@paralleldrive/cuid2';
import type { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { QueryDownloaderContext } from '@containers/QueryDownloader/Context';
import { ReportFormat } from '@/enums';

export type AsyncMutationDownloadsVars = {
  meta?: {
    format?: ReportFormat;
    name?: string;
    title?: string;
  };
};

export const useAsyncMutationDownloader = <
  TData extends { websocketKey: string },
  TContext extends { id: string },
  TVars extends AsyncMutationDownloadsVars,
  TError = AxiosResponse,
>(params: UseMutationOptions<TData, TError, TVars, TContext>): UseMutationResult<TData, TError, TVars, TContext> => {
  const { init, set } = useContext(QueryDownloaderContext);

  const {
    onError,
    onMutate,
    onSuccess,
    ...options
  } = params;

  const mutation = useMutation({
    retry: 1,
    ...options,
    onMutate: vars => {
      const id = createId();
      const extensionsMap: Record<ReportFormat, string> = {
        [ReportFormat.Csv]: 'csv',
        [ReportFormat.Excel]: 'xlsx',
        [ReportFormat.PowerPoint]: 'pptx',
        [ReportFormat.Pdf]: 'pdf',
        [ReportFormat.Word]: 'docx',
        [ReportFormat.Img]: 'img',
      };
      init({
        id,
        queryKey: [id],
        name: vars.meta?.name,
        title: vars.meta?.title,
        extension: vars.meta?.format ? extensionsMap[vars.meta?.format] : undefined,
      });
      const ctx = (onMutate as (variables: TVars) => TContext)?.(vars);
      return { ...ctx, id };
    },
    onSuccess: (res, vars, context: TContext) => {
      set(context.id, {
        websocketKey: res.websocketKey,
      });
      onSuccess?.(res, vars, context);
    },
    onError: (e, vars, context: TContext) => {
      set(context.id, {
        status: 'error',
      });
      onError?.(e, vars, context);
    },
  });

  return mutation;
};
