import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useSelectUser } from '@/containers/Store/hooks';

export const useInitSentry = () => {
  const user = useSelectUser();

  return useCallback(() => {
    Sentry.setUser({
      email: user.contact.email,
      id: `${user.id}`,
    });
  }, [user]);
};