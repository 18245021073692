import snake from 'snakecase-keys';
import { ChatContextType, ChatInstanceItemType } from '@/brand-insights/enums';
import type { Chat, BrandInsightsInstanceContext } from '@/brand-insights/types';
import type * as ChatApi from '@/brand-insights/api/interfaces/chat-instances';
import { safeJsonDate } from './date';
import { transformChatFileUpload } from './file-upload';
import { transformChatQuery } from './query';
import { transformChatSegmentation } from './segmentation';

export function transformChatInstance(data: Chat.Instance<string>): Chat.Instance {
  console.log(data.context)
  return {
    ...data,
    context: transformChatInstanceContext(data.context),
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function transformChatInstanceContext(data: Chat.InstanceContextWithMetadata<string>): Chat.InstanceContextWithMetadata {
  switch (data.type) {
    case ChatContextType.Transcript: {
      return {
        ...data,
        metadata: {
          ...data.metadata,
          date: safeJsonDate(data.metadata.date),
        },
      };
    }
    default: return data;
  }
}

export function transformChatSession(data: ChatApi.GetChatInstance.Response): Chat.Session {
  return {
    chatInstance: transformChatInstance(data.chatInstance),
    queries: data.queries.reduce<Record<string, Chat.Query>>((queries, query) => {
      queries[query.identifier] = transformChatQuery(query);
      return queries;
    }, {}),
    fileUploads: data.fileUploads.map(transformChatFileUpload),
    segmentations: data.segmentations.map(transformChatSegmentation),
  };
}

export function transformChatShareableUsers(data: ChatApi.GetChatShareableUsers.Response): Chat.User[] {
  return data.items;
}

export function prepareContextData(data: BrandInsightsInstanceContext.ItemWithMetadata): BrandInsightsInstanceContext.Item {
  const { metadata, ...ctx } = data;
  return ctx;
}

export function stringifyContextData(data: BrandInsightsInstanceContext.Item): string {
  const context = Buffer.from(JSON.stringify(snake(data))).toString('base64');
  return context;
}