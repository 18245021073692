import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { BasicMarkdown } from '../presentation';
import { SystemUserMessage } from './Message.System';
import { SystemUserErrorMessage } from './Message.System.Error';
import { SystemUserPlaceholderMessage } from './Message.System.Placeholder';
import { UserMessage } from './Message.User';
import { SystemMessageContainer } from './Message.System.Container';
import { QueryEventsContainer } from './QueryEventsContainer';

type Props = {
  canRegenerate: boolean;
  item: Chat.InstanceQueryItem;
};

export const QueryItem = ({ canRegenerate, item }: Props) => {

  return (
    <>
      <MessageContainer>
        <UserMessage
          value={<BasicMarkdown>{item.query.value}</BasicMarkdown>} />
      </MessageContainer>
      <MessageContainer>
        <MessageResponse
          item={item.query}
          hasRegenerateFooter={canRegenerate} />
      </MessageContainer>
    </>
  );
};

type MessageResponseProps = {
  item: Chat.Query;
  hasRegenerateFooter: boolean;
};

// todo: possibly refactor to inverse the layout and have the current inner UI elements
// like variant selector and other actions contain the message response component
const MessageResponse = ({ item, hasRegenerateFooter }: MessageResponseProps) => {
  switch (item.status) {
    case 'loading-extended':
    case 'loading': {
      const isTakingLong = item.status === 'loading-extended';
      return (
        <QueryEventsContainer queryIdentifier={item.identifier}>
          <SystemUserPlaceholderMessage isTakingLong={isTakingLong} />
        </QueryEventsContainer>
      );
    }

    case 'error':
      return (
        <SystemUserErrorMessage
          item={item}
          traceId={item.sentryTraceId}
          hasRegenerateFooter={false} /> // hide for now, can be shown for errored variants but hidden for normal errored queries
      );

    default:
      return (
        <SystemMessageContainer citations={item.citations}>
          <SystemUserMessage
            item={item}
            hasRegenerateFooter={hasRegenerateFooter} />
        </SystemMessageContainer>
      );
  }
};

const MessageContainer = styled.div`
  padding: 15px 20px;
`;