import axios from 'axios';
import { useAsyncState } from '@utils';
import type { Workbook, Worksheet } from '@/types';

export const useFetchWorkbookData = (url: string) => {
  const state = useAsyncState(() => {
    return axios.get<Workbook>(url)
      .then(res => {
        return transformResponse(res.data);
      });

  }, [url]);

  return state;
};

function transformResponse(data: Workbook) {
  return {
    sheets: data.sheets.map(transformSheet),
  };
}

function transformSheet(sheet: Worksheet): Worksheet {
  return {
    ...sheet,
    columns: sheet.columns.length < 6
      ? ['A', 'B', 'C', 'D', 'E', 'F']
      : sheet.columns,
    rows: Math.max(sheet.rows, 30),
  };
}

export default useFetchWorkbookData;