import { useEffect, useMemo, useContext } from 'react';
import {
  useRegisterChatPageContext,
  ChatContextType,
  type BrandInsightsPageContext,
} from '@/brand-insights';
import { ProjectQuantAnalysesContext } from '../Context';
import useProjectState from './useProjectState';

export const useRegisterBrandInsights = () => {
  const state = useProjectState();
  const quantAnalyses = useContext(ProjectQuantAnalysesContext);
  const chatContext: BrandInsightsPageContext.Project = useMemo(() => {
    if (!state.project?.id || !state.features?.insightsChat) return null;

    return {
      type: ChatContextType.Project,
      data: {
        projectId: state.project.id,
      },
      metadata: {
        name: state.project.name,
        projectType: state.project.projectType,
        hasQuantAnalysisFiles: quantAnalyses.length > 0,
      },
    };
  }, [
    state.features?.insightsChat,
    state.project?.id,
    state.project?.name,
    state.project?.projectType,
    quantAnalyses,
  ]);

  const register = useRegisterChatPageContext();

  useEffect(() => {
    if (!chatContext) return;

    if (chatContext.data.projectId && chatContext.metadata.name) {
      register(chatContext);
    }
  }, [register, chatContext]);
};