import { useContext, useMemo } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundLeadershipContext } from '@containers/MedicalOnboarding/Context';
import { useOnSubmitLeadership } from '@containers/MedicalOnboarding/hooks';
import * as Layout from '@/components/MedicalProfile/Layout';
import { Label } from '@/components/MedicalProfile/Label';
import { RadioGroup } from '@/components/MedicalProfile/RadioGroup';
import { useIsInternalUser } from '@/containers/Store';
import type { BackNavigationProps, NextNavigationProps, OnSuccessHandler } from './interfaces';
import styles from './style/AdditionalExpertise.css';

type Props = {
  back?:      BackNavigationProps;
  next?:      NextNavigationProps;
  onSuccess?: OnSuccessHandler<'leadership'>;
};

export const Leadership = ({ back = {}, next = {}, onSuccess, ...props }: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const leadership = useContext(MedicalBackgroundLeadershipContext);

  const handleSubmit = useOnSubmitLeadership(data => {
    ctx.mutation.mutate({
      leadership: {
        items: data.items,
      },
    }, {
      onSuccess: (data, variables) => {
        onSuccess?.(variables);
      },
    });
  }, [
    ctx.mutation,
    onSuccess,
  ]);

  const isInternalUser = useIsInternalUser();

  const validation = useMemo(() => {
    const errors = {
      items: leadership.form.items.some(x => !x.answer),
    };

    const disabled = !isInternalUser && Object.values(errors).some(x => x === true);

    return {
      disabled,
      errors,
    };
  }, [isInternalUser, leadership.form.items]);

  return (
    <Layout.Screen>
      <Layout.Header title={copy.title} />
      {leadership.form.items.map(x =>
        <OptionsQuestion
          key={x.question.identifier}
          label={x.question.value}
          onChange={id => leadership.setValue({
            identifier: x.question.identifier,
            value: id,
          })}
          options={x.question.options}
          selected={x.answer} />)}
      <Layout.Footer>
        {back && <Layout.BackButton />}
        <Layout.NextButton
          disabled={validation.disabled}
          implicitDisable={false}
          loading={ctx.mutation.isLoading}
          onClick={handleSubmit}>
          {next.text}
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

const copy = {
  title: `Have you participated in any of the following thought leadership activities?`,
};

type OptionsQuestionProps = {
  label: string;
  selected: string;
  options: {
    id:    string;
    value: string;
  }[];
  onChange: (optionId: string) => unknown;
};

const OptionsQuestion = ({ selected = '', ...props }: OptionsQuestionProps) => {
  return (
    <div className={styles.item}>
      <Label required>
        {props.label}
      </Label>
      <div className={styles.field}>
        <RadioGroup
          selected={selected}
          onChange={optionId => props.onChange(optionId)}
          options={props.options} />
      </div>
    </div>
  );
};