import { useCallback, useMemo, useState } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import type { ProjectReportType, Project, ProjectReportDataType } from '@/types';
import { useFilteredResponseUsers } from '@containers/GroupProject/hooks';
import { DropDown } from '@/components/DropDown';
import { useModal } from '@/components/Modal/hooks';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import Toast from '@/components/Toast';
import { useDownloadProjectTypeReport } from './hooks/useDownloadProjectReport';
import styles from './style/ProjectReportModal.css';
import { useTrackProjectReportDownload } from './hooks/useTrackProjectReportDownload';

type Props = {
  isParentReport?: boolean;
  project: Pick<Project, 'id' | 'name' | 'parentId'>;
} & Pick<ModalProps, 'open' | 'onClose'>;

type DropdownItem = {
  id: ProjectReportType;
  value: string;
};

type DataTypeProps = {
  dataType: Exclude<ProjectReportDataType, 'survey'>;
  surveyId?: never;
} | {
  dataType: 'survey';
  surveyId: number;
};

type ModalContentProps = {
  title: string;
} & Props & DataTypeProps;

export const ExportCallModal = (props: Props) => {
  return (
    <ModalContents
      {...props}
      dataType={'call'}
      title={'Export Call Data'} />
  );
};

export const ExportSurveyModal = (props: Props & ISurveyId) => {
  return (
    <ModalContents
      {...props}
      dataType={'survey'}
      title={'Export Survey Data'} />
  );
};

const ModalContents = ({ onClose, open, project, isParentReport, dataType, title, surveyId }: ModalContentProps) => {
  const [format, setFormat] = useState<ProjectReportType>();
  const filteredUsers = useFilteredResponseUsers();

  const { download, isFetching } = useDownloadProjectTypeReport({
    format,
    isParentReport,
    projectId: project.id,
    projectParentId: project.parentId,
    dataType,
    filteredUsers,
    surveyId,
  }, {
    onSuccess: data => {
      onClose();
    },
    onError: () => {
      Toast.error({
        body: 'Project Report',
        title: 'There was an error trying to download the report.',
      });
    },
  });

  const track = useTrackProjectReportDownload({ projectId: project.id });

  const handleSubmit = useCallback(() => {
    track(format);
    download({
      extension: format,
      name: `${project.name} Call Data`,
      title: `Generating Report`,
    });
  }, [
    download,
    format,
    project,
    track,
  ]);

  const dropdownItems: DropdownItem[] = useMemo(() => {
    return dataTypeFormats[dataType]
      .map(m => ({
        id: m,
        value: m.toLocaleUpperCase(),
      }));
  }, [dataType]);

  const handleSelect = useCallback((item: DropdownItem) => {
    setFormat(item.id);
  }, []);

  return (
    <Modal
      className={styles.root}
      open={open}
      onClose={onClose}>
      <Header text={title} />
      <div className={styles.dropdown}>
        <DropDown<DropdownItem>
          items={dropdownItems}
          onSelect={handleSelect}
          text={format?.toLocaleUpperCase() || 'Choose Format'}
          getItemValue={(item: DropdownItem) => item.value} />
      </div>
      <div className={styles.btns}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!format}
          onClick={handleSubmit}
          loading={isFetching}>
          Download
        </ButtonActivityIndicator>
      </div>
    </Modal>
  );
};

const dataTypeFormats = {
  'call': ['csv', 'sav', 'xlsx', 'pdf'],
  'survey': ['csv', 'ppt', 'sav', 'xlsx'],
} as Record<ProjectReportDataType, ProjectReportType[]>;

export const useExportCallModal = () => useModal(ExportCallModal);

export const useExportSurveyModal = () => useModal(ExportSurveyModal);