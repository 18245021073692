import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const CopyChat = memo(({ className, size }: Props) => {
  return (
    <svg
      className={className}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg">
      <rect
x="9"
y="9"
width="13"
height="13"
rx="2"
ry="2">
      </rect>
      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    </svg>
  );
});
