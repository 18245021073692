import type { Ref } from 'react';
import { forwardRef } from 'react';
import { ProgressRing } from '@presentation/ProgressRing';
import styles from './style/ProgressOverlay.css';

type Props = {
  progress: number;
};

export const ProgressOverlay = forwardRef(({ progress }: Props, ref: Ref<HTMLDivElement>) => {

  return (
    <div className={styles.root} ref={ref}>
      <ProgressRing
        progress={progress}
        progressColor="var(--gray-m)"
        size={80}
        strokeWidth={8} />
    </div>
  );
});

export default ProgressOverlay;