import type { Dispatch, SetStateAction } from 'react';
import type { EditorView } from '@remirror/pm/view';
import type { QueryObserverResult, UseQueryResult } from '@tanstack/react-query';
import type * as API from '@api/interfaces';
import { createNamedContext } from '@utils';
import type { TranscribeRT, Transcribe, WorkspaceObject } from '@/types';
import type { TranscriptType, TranscriptionRevisionStatus } from '@/enums';
import type { Transcript, TranscriptEntitiesContextValue, TimestampMap, ReplacementMade } from './interfaces';

type TranscriptEditing =
  [boolean, Dispatch<SetStateAction<boolean>>];

type UpdateTimestampsMap = (view: EditorView) => void;

type EditingToolPaneContextValue = {
  enabled: boolean;
  find: {
    active: boolean;
    toggle: () => unknown;
  };
  inuse: boolean;
  reset: () => unknown;
  speakers: {
    active: boolean;
    toggle: () => unknown;
  };
};

type MonologueDetailsEditSpeakerContextValue = {
  keys: number[];
  options: Descriptor[];
};

type TranscriptEnhancementStateContextValue = {
  assigneeId: number;
  enabled: boolean;
  statusId: TranscriptionRevisionStatus;
};

type RefetchTranscriptContextValue = () => Promise<QueryObserverResult<Pick<API.Calls.GetCallTranscript.Response, 'transcript'>>>;

type CallParticipantsQueryContextValue = UseQueryResult<API.Calls.GetConferenceParticipants.Response>;

type CallTranscriptDetailsContextValue = {
  features: {
    insightsChat: boolean;
  };
  title: string;
} & Pick<API.Calls.GetCallTranscript.Response, 'call' | 'project'>
  & Partial<Pick<API.Calls.GetCallTranscript.Response, 'settings'>>;

type WorkspaceTranscriptDetailsContextValue = {
  features: {
    insightsChat: boolean;
  };
  object: WorkspaceObject;
  settings: API.WorkspaceTranscript.GetWorkspaceTranscript.Response['settings'];
};

type TranscriptMediaContextValue = {
  captionsUrl: string;
  isVideo: boolean;
  url: string;
};

type DownloadTranscriptMedia = () => unknown;
type DownloadMediaClip = () => Promise<unknown>;
export type DownloadTranscriptClipParams = {
  fromTs: number;
  toTs: number;
} & ITranscriptId;
export type DownloadTranscriptClip = (data: DownloadTranscriptClipParams) => unknown;

export type TranscriptWorkspaceActions = {
  manageAccess: () => unknown;
  move: () => unknown;
  rename: () => unknown;
};

export type WorkspaceTranscriptAccess = {
  canAccessRecording: boolean;
  canAccessTranscription: boolean;
  canMove: boolean;
  canManageAccess: boolean;
};

export type TranscriptUpdates = {
  corrections: ReplacementMade[];
  addCorrections: (replacements: ReplacementMade[]) => void;
  confirmedAutoCorrections: string[];
  addConfirmedAutoCorrections: (identifiers: string[]) => void;
  overwrittenAutoCorrections: string[];
  addOverwrittenAutoCorrections: (identifiers: string[]) => void;

};

export type RenameTranscriptContextValue = {
  transcriptId: number;
  originalName: string;
  rename: (name: string) => Promise<unknown>;
};

export type TranscriptAccessContextValue = Pick<API.Calls.GetCallTranscript.Response, 'capabilities' | 'features' | 'object' | 'workspace'>;

export const TranscriptAccessContext = createNamedContext<TranscriptAccessContextValue>(undefined, 'Transcript.Access.Context');

export const WorkspaceTranscriptAccessContext = createNamedContext<WorkspaceTranscriptAccess>(undefined, 'WorkspaceTranscriptAccessContext');
export const CallTrancriptWorkspaceActionsContext = createNamedContext<TranscriptWorkspaceActions>(undefined, 'CallTrancriptWorkspaceActionsContext');

export const TranscriptTypeContext = createNamedContext<TranscriptType>(undefined, 'TranscriptTypeContext');
export const DownloadTaggedMomentMediaClipContext = createNamedContext<DownloadMediaClip>(undefined, 'DownloadTaggedMomentMediaClipContext');
export const DownloadTranscriptMediaContext = createNamedContext<DownloadTranscriptMedia>(undefined, 'DownloadTranscriptMediaContext');
export const WorkspaceTranscriptDetailsContext = createNamedContext<WorkspaceTranscriptDetailsContextValue>(undefined, 'Workspace.Transcript.Details.Context');
export const CallTranscriptDetailsContext = createNamedContext<CallTranscriptDetailsContextValue>(undefined, 'Call.Transcript.Details.Context');
export const CallParticipantsQueryContext = createNamedContext<CallParticipantsQueryContextValue>(undefined, 'Call.Participants.Query.Context');
export const EditingToolPaneContext = createNamedContext<EditingToolPaneContextValue>(undefined, 'EditingTool.Pane.Context');
export const MonologueDetailsEditSpeakerContext = createNamedContext<MonologueDetailsEditSpeakerContextValue>(undefined, 'MonologueDetails.EditSpeaker.Context');
export const TranscriptMediaContext = createNamedContext<TranscriptMediaContextValue>(undefined, 'Transcript.Media.Context');
export const TranscriptEnhancementStateContext = createNamedContext<TranscriptEnhancementStateContextValue>(undefined, 'Transcript.EnhancementState.Context');
export const RefetchTranscriptContext = createNamedContext<RefetchTranscriptContextValue>(undefined, 'RefetchTranscriptContext');
export const TranscriptIdContext = createNamedContext<number>(undefined, 'TranscriptIdContext');
export const TranscriptDocumentContext = createNamedContext<TranscribeRT.Transcript>(undefined, 'TranscriptDocumentContext');
export const TranscriptSpeakersContext = createNamedContext<Transcribe.SpeakerMap>(undefined, 'TranscriptSpeakersContext');
export const TranscriptEditingContext = createNamedContext<TranscriptEditing>([false, () => { }], 'TranscriptEditingContext');
export const TranscriptUpdatesContext = createNamedContext<TranscriptUpdates>(undefined, 'Transcript.Corrections.Context');

export const TranscriptTimestampPositionsContext = createNamedContext<TimestampMap>(new Map(), 'TimestampPositionsContext');
export const TranscriptTimestampEndPositionsContext = createNamedContext<TimestampMap>(new Map(), 'TimestampEndPositionsContext');
export const UpdateTranscriptTimestampMapsContext = createNamedContext<UpdateTimestampsMap>(undefined, 'UpdateTimestampMapsContext');

export const TranscriptEntitiesContext = createNamedContext<TranscriptEntitiesContextValue>(undefined, 'Transcript.Entities.Context');
export const TranscriptHydrationContext = createNamedContext<Transcript.Hydration.ContextValue>(undefined, 'Transcript.Hydration.Context');
export const TranscriptConfidenceContext = createNamedContext<Transcript.Confidence.ContextValue>(undefined, 'Transcript.Confidence.Context');
export const TranscriptMetadataContext = createNamedContext<Transcript.Metadata.ContextValue>(undefined, 'TranscriptMetadataContext');
export const TranscriptAutoCorrectContext = createNamedContext<Transcript.AutoCorrect.ContextValue>(undefined, 'Transcript.AutoCorrect.Context');

export const TranscriptMediaPlayerContext = createNamedContext<HTMLMediaElement>(undefined, 'TranscriptMediaPlayerContext');
export const SetTranscriptMediaPlayerContext = createNamedContext<Dispatch<SetStateAction<HTMLMediaElement>>>(undefined, 'SetTranscriptMediaPlayerContext');
export const InitialTranscriptPositionContext = createNamedContext<number>(undefined, 'InitialTranscriptPositionContext');

export const SetQuoteMomentIdContext = createNamedContext<Dispatch<SetStateAction<number>>>(undefined, 'SetQuoteMomentIdContext');
export const TranscriptQuoteMomentIdContext = createNamedContext<number>(undefined, 'TranscriptQuoteMomentIdContext');
export const TranscriptToggleStarQuoteContext = createNamedContext<() => unknown>(undefined, 'TranscriptToggleStarQuoteContext');

export const TranscriptHasMediaContext = createNamedContext<boolean>(true, 'TranscriptHasMediaContext');
export const RenameTranscriptContext = createNamedContext<RenameTranscriptContextValue>(undefined, 'rename-transcript');

export * from './context.highlights';
export * from './context.tagged-moment';