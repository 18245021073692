import { markdownToRawText } from '@/brand-insights/utils/markdown';
import type { Chat } from '@/brand-insights/types';

export function getQueryResponseAsText(response: Chat.QueryResponse): string {
  switch (response.type) {
    case 'raw-text': return response.value;
    case 'markdown': return markdownToRawText(response.value);
    case 'find-quotes-response': return convertQuotesResponseToText(response.value);
    case 'find-quant-analyses-response': return convertQuantAnalysesResponseToText(response.value);
    case 'chart-quant-analyses-response': return response.value.chart.response;
    default: {
      throw new UnreachableCaseError(response);
    }
  }
}

function convertQuotesResponseToText(response: Chat.QuotesData): string {
  const quotes = response.quotes.map(quote => quote.value);
  return quotes.join('\n\n');
}

function convertQuantAnalysesResponseToText(response: Chat.QuantData): string {
  const quants = response.quants.map(quant => quant.title);
  return quants.join(`\n\n`);
}

export function getSegmentationQueryResponseAsText(results: Chat.Segmentation.Result.ResultItem[]): string {
  let buffer = '';

  for (const result of results) {
    buffer += `${result.dimension}\n\n`;
    for (const finding of result.findings) {
      buffer += `${finding.label}:\n\n`;
      if (finding.response.type === 'markdown') {
        buffer += `${markdownToRawText(finding.response.value)}\n\n`;
      }
    }
    buffer += `\n`;
  }

  return buffer;
}