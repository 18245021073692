import type { DependencyList } from 'react';
import { useCallback, useState } from 'react';
import { useMounted } from '@utils/hooks/useMounted';

type AsyncStateInit<T> = {
  error?: undefined;
  loading: boolean;
  value?: T;
};

type AsyncStateReject = {
  error: Error;
  loading: false;
  value?: undefined;
};

type AsyncStateResolve<T> = {
  error?: undefined;
  loading: false;
  value: T;
};

export type AsyncState<T> =
  | AsyncStateInit<T>
  | AsyncStateReject
  | AsyncStateResolve<T>;

export type AsyncFn<R = unknown, P = unknown> = (...args: P[]) => Promise<R | Error>;

const defaultState = {
  loading: false,
};

const useAsyncStateLazy = <T = unknown, U = unknown>(
  fn: AsyncFn<T, U>,
  dependencies: DependencyList = [],
  initialState: AsyncState<T> = defaultState
): [AsyncState<T>, AsyncFn<T, U>] => {

  const [state, setState] = useState<AsyncState<T>>(initialState);
  const isMounted = useMounted();

  const cb = useCallback((...args: U[]) => {
    setState(prev => ({
      loading: true,
      value: prev.value,
    }));

    return fn(...args).then(onResolve, onReject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  function onResolve(data: T) {
    if (isMounted()) {
      setState({
        loading: false,
        value: data,
      });
    }

    return data;
  }

  function onReject(e: Error) {
    if (isMounted()) {
      setState({
        loading: false,
        error: e,
      });
    }
    console.error(e);
    return e;
  }

  return [state, cb];
};

export { useAsyncStateLazy };
export default useAsyncStateLazy;