import { useContext, useMemo } from 'react';
import { ChatContextType } from '@/brand-insights/enums';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { ActiveChatSessionContext, ChatHintsContext } from './context';
import { useChatPermissions } from './hooks';

export const InitialSystemMessage = () => {
  const chat = useContext(ActiveChatSessionContext);
  const hintsCtx = useContext(ChatHintsContext);
  const permissions = useChatPermissions();

  const hints = useMemo(() => {
    return permissions.canModify && hintsCtx.slice(0, 5);
  }, [permissions.canModify, hintsCtx]);

  switch (chat.context?.type) {
    case ChatContextType.Document:
      return (
        <BasicSystemUserMessage
          value="Ask a question about the document!"
          hints={hints} />
      );

    case ChatContextType.Transcript:
      return (
        <BasicSystemUserMessage
          value="Ask a question about the transcript!"
          hints={hints} />
      );

    case ChatContextType.Project: {
      const value = chat.context.metadata.hasQuantAnalysisFiles ? `Ask a question about your project's documents, transcripts, and analysis files!` : `Ask a question about your project's documents and transcripts!`;
      return (
        <BasicSystemUserMessage
            value={value}
            hints={hints} />
      );
    }

    case ChatContextType.Folder:
      return (
        <BasicSystemUserMessage
          value="Ask a question about documents and transcripts in this folder!"
          hints={hints} />
      );

    case ChatContextType.Global:
      return (
        <BasicSystemUserMessage
          value="Ask a question about your documents and transcripts!"
          hints={hints} />
      );

    case ChatContextType.Brand:
      return (
        <BasicSystemUserMessage
          value="Ask a question about your brand's documents and transcripts!"
          hints={hints} />
      );

    default: return null;
  }
};