import { useContext, useMemo } from 'react';
import { MoveCallTranscriptContainer } from '@containers/Workspace.Transcript';
import { ObjectAccessContainer } from '@containers/ObjectAccess';
import type { WorkspaceObject } from '@/types/workspace';
import { useToggle } from '@/utils';
import { MoveCallTranscriptModal } from '@/components/Workspace.Transcript';
import { useObjectAccessModal } from '@/components/ObjectAccess';
import { useRenameTranscriptModal } from '../../components/Transcript/hooks/useRenameTranscriptModal';
import { WorkspaceTranscriptAccessContext, CallTrancriptWorkspaceActionsContext } from './context';
import { RenameTranscriptContainer } from './RenameTranscriptContainer';

type Props =
  {
    object: WorkspaceObject;
    transcript: { id: number };
  } &
  ChildrenProps;

export const CallTranscriptWorkspaceActionsContainer = (props: Props) => {

  const access = useContext(WorkspaceTranscriptAccessContext);

  const [toggleAccessModal, ManageAccessModal] = useObjectAccessModal();
  const [moveObjectModalOpen, toggleMoveObjectModal] = useToggle();
  const [toggleRenameModal, RenameTranscriptModal] = useRenameTranscriptModal();

  const objectId = props.object.id;
  const workspaceId = props.object.workspaceId;
  const transcriptId = props.transcript.id;
  const name = props.object.name;

  const actions = useMemo(() => ({
    manageAccess: toggleAccessModal,
    move: toggleMoveObjectModal,
    rename: toggleRenameModal,
  }), [
    toggleAccessModal,
    toggleMoveObjectModal,
    toggleRenameModal,
  ]);

  return (
    <CallTrancriptWorkspaceActionsContext.Provider value={actions}>
      {props.children}
      {access.canMove &&
        <MoveCallTranscriptContainer enabled={moveObjectModalOpen} objectId={objectId}>
          <MoveCallTranscriptModal
            open={moveObjectModalOpen}
            onClose={toggleMoveObjectModal} />
        </MoveCallTranscriptContainer>}
      {access.canManageAccess &&
        <ObjectAccessContainer objectId={objectId} workspaceId={workspaceId}>
          <ManageAccessModal />
        </ObjectAccessContainer>
      }

      {access.canMove &&
      <RenameTranscriptContainer transcriptId={transcriptId} originalName={name}>
        <RenameTranscriptModal />
      </RenameTranscriptContainer>
      }

    </CallTrancriptWorkspaceActionsContext.Provider>
  );
};