import { Link } from 'react-router-dom';
import { cx } from '@utils';
import type { BackLinkProps, BackProps } from './interfaces';
import styles from './style/Back.css';

export const Back = ({ className, ...props }: BackProps) => (
  <div
    className={cx(styles.root, className)}
    {...props}>
    Back
  </div>
);

export const BackLink = ({ className, ...props }: BackLinkProps) => (
  <Link {...props}>
    <Back className={className} />
  </Link>
);
