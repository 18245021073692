import { SentryErrorBoundary } from './Sentry.ErrorBoundary';
import ReactQueryClient from './ReactQueryClient';
import { QueryParamsClient } from './QueryParamsClient';

type Props = ChildrenProps;

export default function RootVendors({ children }: Props) {
  return (
    <SentryErrorBoundary>
      <ReactQueryClient>
        <QueryParamsClient>
          {children}
        </QueryParamsClient>
      </ReactQueryClient>
    </SentryErrorBoundary>
  );
}

export { RootVendors };