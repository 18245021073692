import { useCallback, useContext, useMemo } from 'react';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { useHistory } from 'react-router-dom';
import { ProjectStateContext } from '@containers/GroupProject/Context';
import * as scheduler from '@containers/Scheduler/utils';
import { useSelectUser } from '@containers/Store';
import { Tooltip } from '@presentation/Tooltip';
import { hasSchedulingPermission, hasInternalAdminRole, getLocationFor } from '@utils';
import { ProjectCallsQueryContext } from '@containers/Group.Project.Calls/Context';
import type { ContextMenuItem } from '@/components/ContextMenu';
import { ContextMenu } from '@/components/ContextMenu';
import { CallCancellationContext } from '$admin/components/Call.Cancellation/Context';
import { CallMarkCompleteContext } from '$admin/components/Call.MarkComplete/Context';
import { CallReschedulingContext } from '$admin/components/Call.Rescheduling/Context';
import { ParamsContext } from '$admin/components/Scheduling/Context';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const history = useHistory();
  const ctx = {
    cancellation: useContext(CallCancellationContext),
    markcomplete: useContext(CallMarkCompleteContext),
    query: useContext(ProjectCallsQueryContext),
    rescheduling: useContext(CallReschedulingContext),
    scheduling: useContext(ParamsContext),
    state: useContext(ProjectStateContext),
  };

  const handleClickCancel = useCallback(() => {
    ctx.cancellation.setContext({
      callId: props.row.original.call.id,
      externalRespondent: props.row.original.respondent.offPlatform,
    });
  }, [
    ctx.cancellation,
    props.row.original.call.id,
    props.row.original.respondent.offPlatform,
  ]);

  const handleClickMarkComplete = useCallback(() => {
    ctx.markcomplete.setContext({
      callId: props.row.original.call.id,
    });
  }, [
    ctx.markcomplete,
    props.row.original.call.id,
  ]);

  const handleClickReschedule = useCallback(() => {
    ctx.rescheduling.setContext({
      callId: props.row.original.call.id,
      externalRespondent: props.row.original.respondent.offPlatform,
    });
  }, [
    ctx.rescheduling,
    props.row.original.call.id,
    props.row.original.respondent.offPlatform,
  ]);

  const showSchedule = useMemo(() => {
    return scheduler.calls.isScheduleable({
      call: props.row.original.call,
      memberStatusId: props.row.original.respondent.statusId,
    });
  }, [
    props.row.original.call,
    props.row.original.respondent.statusId,
  ]);

  const user = useSelectUser();

  const editable = useMemo(() => {
    const isAdmin = hasInternalAdminRole(user);

    if (isAdmin) return true;

    return hasSchedulingPermission(user)
      && user.id === props.row.original.call.ownerId;

  }, [
    props.row.original.call.ownerId,
    user,
  ]);

  const disabled = useMemo(() => {
    return {
      cancel: !editable || !scheduler.calls.canCancelCall(props.row.original.call),
      complete: !editable,
      reschedule: !editable || !scheduler.calls.isConfirmed(props.row.original.call),
      schedule: !editable || !showSchedule,
    };
  }, [
    editable,
    props.row.original.call,
    showSchedule,
  ]);

  const captureSheetContextMenuItems = useMemo<ContextMenuItem[]>(() => {
    if (!props.row.original.captureSheets?.length) return [];

    return props.row.original.captureSheets.map(sheet => ({
      label: sheet.responseId ? `View "${sheet.survey.name}" Response` : `Resume "${sheet.survey.name}"`,
      onClick: () => {
        if (sheet.responseId) {
          history.push(getLocationFor.project.surveyResponse({
            projectSurveyResponseId: sheet.responseId,
            slug: ctx.state.project.slug,
          }));
        } else {
          const newWindow = window.open(getLocationFor.conference.captureSheet({
            callId: props.row.original.call.id,
            surveyId: sheet.survey.id,
          }).pathname, '_blank', `height=${screen.height},width=${screen.width}`);

          if (newWindow) {
            newWindow.onunload = () => {
              ctx.query.refetch();
            };
          }
        }
      },
    }));
  }, [history, ctx.state.project.slug, props.row.original.captureSheets, props.row.original.call.id, ctx.query]);

  if (scheduler.calls.isMissed(props.row.original.call)) {
    return (
      <Action
        Icon={TaskAltOutlinedIcon}
        disabled={disabled.complete}
        onClick={handleClickMarkComplete}
        title="Mark Complete" />
    );
  }

  return (
    <div className={styles.btns}>
      <Action
        Icon={EventAvailableOutlinedIcon}
        disabled={disabled.schedule}
        onClick={() => ctx.scheduling.setContext({
          callId: props.row.original.call.id,
          projectId: ctx.state.project.id,
          userId: props.row.original.respondent.id,
        })}
        title="Schedule Call" />
      <Action
        Icon={EventRepeatOutlinedIcon}
        disabled={disabled.reschedule}
        onClick={handleClickReschedule}
        title="Reschedule Call" />
      <Action
        Icon={EventBusyOutlinedIcon}
        disabled={disabled.cancel}
        onClick={handleClickCancel}
        title="Cancel Call" />

      {captureSheetContextMenuItems.length > 0 &&
        <ContextMenu
          items={captureSheetContextMenuItems}
          AnchorElement={CaptureSheetButton} />}
    </div>
  );
};

Cell.displayName = 'Column.Actions.Cell';

type ActionProps = {
  Icon: React.ComponentType<SvgIconProps>;
  disabled?: boolean;
  onClick: () => void;
  title: string;
};

const Action = ({ Icon, ...props }: ActionProps) => {
  return (
    <button
      className={styles.action}
      disabled={props.disabled}
      onClick={props.onClick}>
      <Tooltip title={!props.disabled ? props.title : null}>
        <div className={styles.icon}>
          <div className={styles.bg}>
            <Icon className={styles.svg} />
          </div>
        </div>
      </Tooltip>
    </button>
  );
};

const CaptureSheetButton = () => {
  return (
    <div className={styles.icon}>
      <div className={styles.bg}>
        <BarChartOutlined className={styles.svg} />
      </div>
    </div>
  );
};