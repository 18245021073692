import styled from '@emotion/styled';

export const BaseMessageButton = styled.button(({ theme }) => ({
  borderRadius: 15,

  color: theme.palette.primary.light6,
  backgroundColor: theme.palette.primary.light5,
  fontFamily: theme.fonts.semiBold,
  padding: `5px 8px`,
  width: '100%',
  height: 30,
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,

  '&:hover': {
    color: theme.palette.primary.light6,
    backgroundColor: theme.palette.btnPrimary.bg,
    borderColor: theme.palette.btnPrimary.border,
  },

  '&:disabled': {
    cursor: 'initial',
    color: theme.palette.btnPrimary.disabled.color,
    backgroundColor: theme.palette.btnPrimary.disabled.bg,
    borderColor: theme.palette.btnPrimary.disabled.border,
  },
}));