import { memo, useContext } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import type { Chat } from '@/brand-insights/types';
import { useOptionsColors } from '@/components/SurveyResponses/hooks/usePaletteColors';
import { BrandingContainer } from '@containers/Branding';
import { ChatHintType } from '@/brand-insights/enums';
import { getProjectQuantAnalysisLocation } from '@utils/navigation';
import { TextQueryHint, MessageQueryHintContainer } from '../presentation';
import { BrandInsightsModalContext } from '../BrandInsights/context';
import { NivoStackedBarChart } from './Message.Response.Chart.Stacked.Bar';
import { NivoPieChart } from './Message.Response.Chart.Pie';
import { NivoLineChart } from './Message.Response.Chart.Line';
import { NivoBarChart } from './Message.Response.Chart.Bar';
import { ChartLegend } from './Message.Response.Chart.Legend';
import { ChartRefContext, ChartInfoContext } from './context';

type Props = {
    data: Chat.ChartData;
};

export const ChartQuantAnalysesResponse = memo((props: Props) => {
  return (
    <BrandingContainer>
      <ChartQuantAnalysesResponseContent {...props} />
    </BrandingContainer>
  );
});

const ChartQuantAnalysesResponseContent = memo((props: Props) => {
  const colors = useOptionsColors();
  const legend = [];
  const history = useHistory();
  const [isOpen, toggleModal] = useContext(BrandInsightsModalContext);
  const [ref, setRef] = useContext(ChartRefContext);
  const [chart, setChart] = useContext(ChartInfoContext);

  const handleClick = () => {
    const location = getProjectQuantAnalysisLocation({
      slug: props.data.projectId.toString(),
      quantAnalysisId: props.data.quantId,
      itemId: props.data.quantItemId,
    }).pathname;

    history.push(location);
    toggleModal();
  };

  const renderChart = (chartType: string) => {
    switch (chartType) {
      case 'stacked-bar': {
        const chart = props.data.chart.chart as Chat.BarChart;
        for (let i = 0; i < chart.data.length; i++) {
          legend.push({ key: chart.data[i].x, color: colors[i] });
        }
        return <NivoStackedBarChart chart={chart} colors={colors} />;
      }

      case 'bar': {
        const chart = props.data.chart.chart as Chat.BarChart;
        for (let i = 0; i < chart.data.length; i++) {
          legend.push({ key: chart.data[i].x, color: colors[i] });
        }
        return <NivoBarChart chart={chart} colors={colors} />;
      }

      case 'pie': {
        const chart = props.data.chart.chart as Chat.PieChart;
        for (let i = 0; i < chart.data.length; i++) {
          legend.push({ key: chart.data[i].label, color: colors[i] });
        }
        return <NivoPieChart chart={chart} colors={colors} />;
      }
      case 'line': {
        const chart = props.data.chart.chart as Chat.LineChart;
        return <NivoLineChart chart={chart} colors={colors} />;
      }

      default:
        return null;
    }
  };

  if (!props.data.chart || !props.data.chart.chart || props.data.chart.type === 'none') {
    setChart(null);
    const hint: Chat.QueryHint.Item = {
      displayValue: 'Go to analysis tab',
      type: ChatHintType.Text,
    };

    // the response should only be displayed if props.data.chart is not null
    return (
      <div>
        {props.data.chart &&
        <div>
          {props.data.chart.response}
        </div>
        }

        <NoChartMessage>
          <div>Sorry, I was not able to generate a chart for this analysis.</div>
          <br />
          <MessageQueryHintContainer>
            <TextQueryHint
          key={props.data.projectId}
          hint={hint}
          onClick={() => handleClick()} />
          </MessageQueryHintContainer>
        </NoChartMessage>
      </div>
    );}

  setChart(props.data.chart);

  return (

    <div>

      <div>
        {props.data.chart.response}
      </div>

      <div ref={node => setRef(node)}>
        <Chart>
          {props.data.chart.chart &&
          <>
            <Title>{props.data.chart.chart.title}</Title>
            {renderChart(props.data.chart.type)}
            {legend.length > 0 && <ChartLegend legend={legend} />}
          </>}
        </Chart>
      </div>
    </div>

  );
});

const Chart = styled.div`
  border: 1px solid var(--gray-l);
  border-radius: 15px;
  padding: 12px;
  margin-top: 10px;
  background-color: white;
`;

const Title = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
}));

const NoChartMessage = styled.div`
  margin-top: 10px;
`;
