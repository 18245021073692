import { useContext, useMemo } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundLeadershipContext } from '@containers/MedicalOnboarding/Context';
import { CompletionMedicalStepperContext } from '@containers/ProjectOnboarding.Completion/Context';
import * as Layout from '@/components/MedicalProfile/Layout';
import { Label } from '@/components/MedicalProfile/Label';
import { RadioGroup } from '@/components/MedicalProfile/RadioGroup';
import { useIsInternalUser } from '@/containers/Store';
import styles from './style/Medical.css';

type Props = unknown;

export const Leadership = (props: Props) => {
  const ctx = {
    background: useContext(MedicalBackgroundContext),
    leadership: useContext(MedicalBackgroundLeadershipContext),
    stepper: useContext(CompletionMedicalStepperContext),
  };

  const isInternalUser = useIsInternalUser();

  const validation = useMemo(() => {
    const errors = {
      items: ctx.leadership.form.items.some(x => !x.answer),
    };

    const disabled = !isInternalUser && Object.values(errors).some(x => x === true);

    return {
      disabled,
      errors,
    };
  }, [ctx.leadership.form.items, isInternalUser]);

  return (
    <Layout.Screen>
      <Layout.Header title={copy.title} />
      {ctx.leadership.form.items.map(x =>
        <OptionsQuestion
          key={x.question.identifier}
          label={x.question.value}
          onChange={id => ctx.leadership.setValue({
            identifier: x.question.identifier,
            value: id,
          })}
          options={x.question.options}
          selected={x.answer} />)}
      <Layout.Footer>
        <Layout.BackButton onClick={ctx.stepper.back} />
        <Layout.NextButton
          disabled={validation.disabled}
          implicitDisable={false}
          loading={ctx.background.mutation.isLoading}
          onClick={ctx.stepper.next}>
          Next
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

Leadership.displayName = 'Medical.Leadership';

const copy = {
  title: `Have you participated in any of the following thought leadership activities?`,
};

type OptionsQuestionProps = {
  label: string;
  selected: string;
  options: {
    id: string;
    value: string;
  }[];
  onChange: (optionId: string) => unknown;
};

const OptionsQuestion = ({ selected = '', ...props }: OptionsQuestionProps) => {
  return (
    <div className={styles.item}>
      <Label required>
        {props.label}
      </Label>
      <div className={styles.field}>
        <RadioGroup
          selected={selected}
          onChange={optionId => props.onChange(optionId)}
          options={props.options} />
      </div>
    </div>
  );
};