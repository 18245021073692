import { memo, useMemo } from 'react';
import { File as GenericFileIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { TranscriptFileIcon } from '@/components/icons';
import { cx } from '@/utils';
import type { IWorkspaceTranscriptItem } from '@/types/workspace.table';
import { formatCreatedOnDate } from './Files.Table.Grid.utils';
import { useTranscriptLink } from './hooks';
import styles from './style/Files.Table.Grid.css';

type Props = {
  item: IWorkspaceTranscriptItem;
};

export const Transcript = ({ item }: Props) => {

  const createdOn = useMemo(() => {
    return formatCreatedOnDate(item.object.createdOn);
  }, [item.object.createdOn]);

  const to = useTranscriptLink(item);

  return (
    <Link
      className={cx(styles.link, styles.file)}
      to={to}
      draggable={false}>
      <div className={styles.thumb}>
        <GenericTranscriptThumb />
      </div>
      <div className={styles.info}>
        <div className={styles.icon}>
          <TranscriptFileIcon
            className={styles.icon} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{item.object.name}</div>
          <div className={styles.lastModified}>{createdOn}</div>
        </div>
      </div>
    </Link>
  );
};

const GenericTranscriptThumb = memo(() => {
  return (
    <div className={styles.genericThumb}>
      <GenericFileIcon className={styles.icon} />
    </div>
  );
});