import { Component } from 'react';
import { cx } from '@utils';
import Input from '@/components/Input';
import styles from './style.css';

class AutoCompleteTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      index: -1,
      value: props.value || '',
    };
  }

  static defaultProps = {
    allowCustom: true,
    focusableClasses: {},
    items: [],
  };

  handleBlur = e => {
    this.toggleVisibility();
  };

  handleChange = e => {
    this.setState({
      id: '',
      index: -1,
      value: e.target.value,
    });

    this.props.onChange(e);
  };

  handleClick = index => () => {
    const selected = this.props.items[index];
    this.handleSelect(selected);
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (this.state.index === -1 || this.props.items[this.state.index].isCustom) {
        this.handleSelectCustom();
      } else {
        this.handleSelect(this.props.items[this.state.index]);
      }
    }

    const last = this.props.items.length - 1;

    if (e.key === 'ArrowDown') {
      const next = this.state.index + 1;
      const highlightedIndex = next > last ? 0 : next;
      this.setInputValue(highlightedIndex);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const next = this.state.index - 1;
      const highlightedIndex = this.state.index <= 0 ? last : next;
      this.setInputValue(highlightedIndex);
    }
  };

  handleSelect = item => {
    this.props.onSelect(item);

    this.setState({
      id: '',
      index: -1,
      value: '',
    });
  };

  handleSelectCustom = e => {
    if (!this.props.allowCustom) return;

    this.handleSelect({
      metadata: { key: `functions-${Date.now()}` },
      name: this.state.value.trim(),
      isCustom: true,
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (!this.state.id) {
      return;
    }

    const item = this.props.items.find(x => x.id === this.state.id);
    this.handleSelect(item);
  };

  getVisibilityStyles = () => {
    return this.props.items.length && this.state.visible
      ? `${styles.list} ${styles.open}`
      : styles.list;
  };

  renderItem = (item, i) => {
    const val = this.props.getItemValue(item);
    const mainClassName = this.state.index === i ? styles.highlight : styles.item;
    const recommendClass = item.highlight ? styles.recommended : '';

    return (
      <div
        className={[mainClassName, recommendClass].join(' ')}
        key={item.id}
        onMouseDown={item.isCustom ? this.handleSelectCustom : this.handleClick(i)}>
        {item.isCustom ? `Add "${val}"` : val}
      </div>
    );
  };

  setInputValue = index => {
    const selected = this.props.items[index];

    this.setState({
      id: selected ? selected.id : '',
      index,
      value: selected ? this.props.getItemValue(selected) : this.state.value,
    });
  };

  toggleVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Input
          autoComplete="off"
          autoFocus={this.props.autoFocus}
          className={cx(styles.input, this.props.className)}
          focusableClasses={this.props.focusableClasses}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.toggleVisibility}
          onKeyDown={this.handleKeyDown}
          ref={this.props.refProp}
          type="text"
          value={this.state.value} />
        <div className={styles.results}>
          <div className={this.getVisibilityStyles()}>
            {this.props.items.map(this.renderItem)}
          </div>
        </div>
      </form>
    );
  }
}

export default AutoCompleteTags;