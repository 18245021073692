import { useCallback, useState } from 'react';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useLock, useMounted } from '@/utils';
import { useRenameTranscriptContext } from '../../containers/Transcript/hooks/useRenameTranscriptContext';
import styles from '../Workspace.File/style/RenameFileModal.css';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

export const RenameTranscriptModal = ({ onClose, open  }: Props) => {
  const { transcriptId, originalName, rename } = useRenameTranscriptContext();
  const isMounted = useMounted();
  const [name, setName] = useState<string>(originalName);
  const [busy, lock] = useLock();

  const handleSave = useCallback(() => {
    return rename(name)
      .then(_ => {
        if (isMounted()) {
          onClose();
        }
      });
  }, [
    isMounted,
    name,
    onClose,
    rename,
  ]);

  const Footer = useCallback(() => {
    const canSave = !!name && name !== originalName;
    return (
      <ButtonSet className={styles.footer}>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <Button.Affirmative
          className={styles.btn}
          disabled={!canSave}
          onClick={lock(handleSave)}
          variant="brick">
          Save
        </Button.Affirmative>
      </ButtonSet>
    );
  }, [name, originalName, onClose, lock, handleSave]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <Modal onClose={onClose} open={open}>
      <Header
        className={styles.header}
        text="Rename Transcript" />
      <div className={styles.body}>
        <div className={styles.input}>
          <Input
            name="file-name"
            onChange={handleNameChange}
            value={name} />
        </div>
      </div>
      <Footer />
    </Modal>
  );
};