import { useCallback, useContext, useMemo, Fragment } from 'react';
import styled from '@emotion/styled';
import { ChatActionType, ChatContextType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { ChatPageContextDataContext } from './context';
import { QueryFilesSuggestedAction } from './Message.SuggestedActions.Files';
import { SurveyQuestionSuggestedActions } from './Message.SuggestedActions.Question';

type Props = {
  attachment: Chat.SuggestedActions.Attachment;
  className?: string;
};

export const SuggestedActionsAttachment = (props: Props) => {

  const contextData = useContext(ChatPageContextDataContext);

  const renderItem = useCallback((item: Chat.SuggestedActions.Value) => {
    switch (item.type) {
      case ChatActionType.SummarizeFolder:
      case ChatActionType.QuerySurveyQuestion:
        return null;

      case ChatActionType.AnswerQuantAnalyses:
        return null;

      case ChatActionType.QueryFiles:
        return <QueryFilesSuggestedAction item={item} query={props.attachment.query} />;

      default:
        throw new UnreachableCaseError(item);
    }
  }, [props.attachment.query]);

  const questionSuggestions = useMemo(() => {
    const items: Chat.SuggestedActions.QuerySurveyQuestion[] = [];
    props.attachment.items.forEach(item => {
      if (item.type === ChatActionType.QuerySurveyQuestion) {
        items.push(item);
      }
    });
    return items;
  }, [props.attachment.items]);

  const renderSurveyActions = useCallback(() => {
    if (!questionSuggestions.length) return null;

    if (contextData.type === ChatContextType.Project) {
      return (
        <SurveyQuestionSuggestedActions
          query={props.attachment.query}
          items={questionSuggestions} />
      );
    }

    if (contextData.type === ChatContextType.Global) {
      return (
        <SurveyQuestionSuggestedActions
          query={props.attachment.query}
          items={questionSuggestions} />
      );
    }
  }, [
    contextData.type,
    questionSuggestions,
    props.attachment.query,
  ]);

  return (
    <StyledRoot className={props.className}>
      {renderSurveyActions()}
      {props.attachment.items.map((item, i) => (
        <Fragment key={i}>
          {renderItem(item)}
        </Fragment>
      ))}
    </StyledRoot>
  );
};

const StyledRoot = styled.div(({ theme }) => ({
  '& > *': {
    marginBottom: 15,
  },

  '& > *:last-child': {
    marginBottom: 0,
  },
}));