import { format } from 'date-fns';
import { Skeleton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useContext, useState, useCallback } from 'react';
import { CallTranscriptDetailsContext, TranscriptAccessContext, RefetchTranscriptContext, TranscriptIdContext, TranscriptMediaContext } from '@containers/Transcript/context';
import { TranscriptDownloadMenu } from '@screens/Transcript/DownloadMenu';
import styles from '@screens/Transcript/style/Header.css';
import { DiscoverInsights } from '@/components/GroupProject/DiscoverInsights';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { EditTitle } from '@screens/Transcript/EditTitle';
import * as $api from '@api';
import Pencil from '@/components/icons/Pencil';
import { Access } from './Header.Access';

export const TranscriptHeader = () => {
  const access = useContext(TranscriptAccessContext);
  const data = useContext(CallTranscriptDetailsContext);
  const transcriptId = useContext(TranscriptIdContext);
  const media = useContext(TranscriptMediaContext);

  const isInternalUser = useIsInternalUser();

  const has = {
    call: !!data?.call?.timeEnd,
    chat: !!access.features?.insightsChat,
    project: !!data?.project?.name,
    object: !!access.object?.id,
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.field}>
          {!has.project
            ? <Skeleton
              className={styles.skel}
              width={200}
              height={30} />
            : <div className={styles.label}>Title:</div>}
          {!has.project
            ? <Skeleton width={250} height={30} />
            : <Title value={data.title || data.call.title || data.project.name} transcriptId={transcriptId} />}
        </div>
        <div className={styles.actions}>
          {!has.object && !isInternalUser && (
            <Skeleton
              height={30}
              width={50}
              variant="rounded" />
          )}
          {has.object && has.chat && !isInternalUser && (
            <DiscoverInsights className={styles.discover} />
          )}
          <Access loading={!has.object} />
          <TranscriptDownloadMenu
            callId={data?.call?.id}
            projectId={data?.project?.id}
            publishType={data?.settings?.publishType}
            transcriptId={transcriptId}
            source={{
              type: 'video',
              url: media.url,
            }} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.field}>
          {!has.call
            ? <Skeleton
              className={styles.skel}
              width={200}
              height={30} />
            : <div className={styles.label}>Completed On:</div>}
          {!has.call
            ? <Skeleton width={250} height={30} />
            : <div className={styles.value}>{format(new Date(data.call.timeEnd), 'MMM do yyyy hh:mm aa')}
            </div>}
        </div>
      </div>
    </>
  );
};

TranscriptHeader.displayName = 'Call.Transcript.Header';

type TitleProps = {
  transcriptId: number;
  value: string;
};

const Title = (props: TitleProps) => {
  const [editing, setEditing] = useState(false);
  const refetch = useContext(RefetchTranscriptContext);
  const mutation = useMutation({
    mutationKey: ['rename-transcript-title-using-pencil'],
    mutationFn: (value: string) => {
      return $api.transcripts.renameTranscript({
        transcriptId: props.transcriptId,
        name: value,
      });
    },
  });

  const handleSubmit = useCallback((title: string) => {
    mutation.mutateAsync(title)
    .then(() => refetch())
    .then(() => setEditing(false));
  }, [mutation, refetch]);

  if (!editing) {
    return (
      <div className={styles.edit}>
        <div className={styles.value}>{props.value}</div>
        <div className={styles.pencil}>
          <Pencil size={16}  onClick={() => setEditing(true)} />
        </div>
      </div>
    );
  }

  return (
    <EditTitle
      disabled={mutation.isLoading}
      onCancel={() => setEditing(false)}
      onSubmit={handleSubmit}
      value={props.value} />
  );
};