import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type { WorkspaceFile, WorkspaceObject } from '@/types';
import { useTranscriptDownloadModal } from '@/screens/ProjectSummary/TranscriptDownloadModal';
import { MoveObjectContainer, ObjectOwnershipContainer } from '@containers/WorkspaceObject';
import { ObjectAccessContainer } from '@containers/ObjectAccess';
import { getLocationFor, useToggle } from '@/utils';
import { useDeleteFilePrompt } from '@/components/Workspace.File/hooks/useDeleteFilePrompt';
import { useRenameFileModal } from '@/components/Workspace.File/hooks/useRenameFileModal';
import { WorkspaceObjectType } from '@/enums';
import { MoveObjectModal } from '@/components/WorkspaceObject';
import { useObjectOwnershipModal } from '@/components/WorkspaceObject/hooks';
import { useObjectAccessModal } from '@/components/ObjectAccess';
import { RenameFileContainer } from './RenameFileContainer';
import { DeleteFileContainer } from './DeleteFileContainer';
import type { FileActionsContextValue } from './Context';
import { FileActionsContext } from './Context';
import { useDownloadFileContext, useFileMenuItems } from './hooks';
import { TranscribeFileContainer } from './TranscribeFileContainer';

type Props =
  {
    file: WorkspaceFile;
    object: WorkspaceObject;
    transcript: { id: number };
  } &
  ChildrenProps;

export const FileActionsContainer = (props: Props) => {

  const menu = useFileMenuItems();
  const history = useHistory();

  const [toggleOwnershipModal, ObjectOwnershipModal] = useObjectOwnershipModal();
  const [toggleDeletePrompt, DeleteFilePrompt] = useDeleteFilePrompt();
  const [toggleRenameModal, RenameFileModal] = useRenameFileModal();
  const [toggleAccessModal, ManageAccessModal] = useObjectAccessModal();
  const [moveObjectModalOpen, toggleMoveObjectModal] = useToggle();
  const [toggleDownloadTranscriptModal, DownloadTranscriptModal] = useTranscriptDownloadModal();

  const download = useDownloadFileContext();

  const viewFile = useCallback(() => {
    const location = getLocationFor.workspaces.file({
      fileId: props.file.id,
    });
    history.push(location);
  }, [history, props.file.id]);

  const actions: FileActionsContextValue = useMemo(() => ({
    changeOwner: toggleOwnershipModal,
    delete: toggleDeletePrompt,
    download,
    downloadTranscript: toggleDownloadTranscriptModal,
    manageAccess: toggleAccessModal,
    move: toggleMoveObjectModal,
    rename: toggleRenameModal,
    viewFile,
  }), [
    download,
    toggleAccessModal,
    toggleDeletePrompt,
    toggleDownloadTranscriptModal,
    toggleMoveObjectModal,
    toggleOwnershipModal,
    toggleRenameModal,
    viewFile,
  ]);

  const fileId = props.file.id;
  const objectId = props.file.objectId;
  const workspaceId = props.object.workspaceId;

  return (
    <FileActionsContext.Provider value={actions}>
      <TranscribeFileContainer
        workspaceId={workspaceId}
        fileId={fileId}
        transcriptId={props.transcript.id}>
        {props.children}
        {menu.canChangeOwner &&
          <ObjectOwnershipContainer object={props.object} workspaceId={workspaceId}>
            <ObjectOwnershipModal />
          </ObjectOwnershipContainer>}
        {menu.canMove &&
          <MoveObjectContainer
            enabled={moveObjectModalOpen}
            objectId={objectId}
            objectType={WorkspaceObjectType.File}>
            <MoveObjectModal
              open={moveObjectModalOpen}
              onClose={toggleMoveObjectModal} />
          </MoveObjectContainer>}
        {menu.canDelete &&
          <DeleteFileContainer
            fileId={fileId}
            workspaceId={workspaceId}
            objectId={objectId}>
            <DeleteFilePrompt />
          </DeleteFileContainer>}
        {menu.canRename &&
          <RenameFileContainer file={props.file} workspaceId={workspaceId}>
            <RenameFileModal />
          </RenameFileContainer>}
        {menu.canManageAccess &&
          <ObjectAccessContainer objectId={objectId} workspaceId={workspaceId}>
            <ManageAccessModal />
          </ObjectAccessContainer>
        }
        {menu.canViewTranscript && (
          <DownloadTranscriptModal transcriptId={props.transcript?.id} />
        )}
      </TranscribeFileContainer>
    </FileActionsContext.Provider>
  );
};