import { ChatCitationType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';

export function isDocumentCitation(item: Chat.Citation): item is Chat.Citation<ChatCitationType.Document> {
  return item.type === ChatCitationType.Document;
}

export function isTranscriptCitation(item: Chat.Citation): item is Chat.Citation<ChatCitationType.Transcript> {
  return item.type === ChatCitationType.Transcript;
}

export function isQuantAnalysisCitation(item: Chat.Citation): item is Chat.Citation<ChatCitationType.QuantAnalysis> {
  return item.type === ChatCitationType.QuantAnalysis;
}

export function getSnippetLink(item: Chat.Citation, snippet: Chat.CitationSnippet): string {
  if (isDocumentCitation(item)) {
    const docSnippet = snippet as Chat.CitationSnippet<ChatCitationType.Document>;
    return docSnippet.metadata?.page
      ? `${item.link}?p=${docSnippet.metadata.page}`
      : item.link;
  } else if (isTranscriptCitation(item)) {
    const tSnippet = snippet as Chat.CitationSnippet<ChatCitationType.Transcript>;
    return tSnippet.metadata?.start
      ? `${item.link}?s=${tSnippet.metadata.start}`
      : item.link;
  }
  return item.link;
}

export function getSnippetDisplayValue(snippet: Chat.CitationSnippet, type: ChatCitationType): string {
  switch (type) {
    case ChatCitationType.Document:
      return getDocumentSnippetDisplayVale(snippet as Chat.CitationSnippet<ChatCitationType.Document>);
    case ChatCitationType.Transcript:
      return getTranscriptSnippetDisplayValue(snippet as Chat.CitationSnippet<ChatCitationType.Transcript>);
    case ChatCitationType.SurveyQuestionResponses:
      return getSurveyQuestionSnippetDisplayValue(snippet);
    case ChatCitationType.QuantAnalysis:
      return getQuantAnalysisSnippetDisplayValue(snippet as Chat.CitationSnippet<ChatCitationType.QuantAnalysis>);
  }
}

export function getDocumentSnippetDisplayVale(snippet: Chat.CitationSnippet<ChatCitationType.Document>): string {
  if (snippet.title) return snippet.title;

  if (snippet.metadata?.page) return `Page ${snippet.metadata.page}`;

  return 'Document';
}

export function getTranscriptSnippetDisplayValue(snippet: Chat.CitationSnippet<ChatCitationType.Transcript>): string {
  if (snippet.title) return snippet.title;

  if (snippet.metadata?.start && snippet.metadata?.end) {
    return `${secondsToTime(snippet.metadata.start)} - ${secondsToTime(snippet.metadata.end)}`;
  }

  return `Transcript`;
}

export function getSurveyQuestionSnippetDisplayValue(snippet: Chat.CitationSnippet): string {
  return snippet.title;
}

function secondsToTime(value: number) {

  const time = new Date(value * 1000).toISOString().substring(11, 19);

  return time.startsWith('00') ? time.substring(3) : time;
}

export function getQuantAnalysisSnippetDisplayValue(snippet: Chat.CitationSnippet<ChatCitationType.QuantAnalysis>): string {
  return snippet.title;
}