import type { AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import * as $api from '@api';
import type { GetWorkspaceTranscript } from '@/services/api/interfaces/workspace.transcript';
import type { UseQueryOptions } from '../hooks';
import { useQueryWrapper } from '../hooks';

type Data = GetWorkspaceTranscript.Response;
type QueryKey = readonly [string, ITranscriptId];

const buildQueryKey = (data: ITranscriptId): QueryKey => {
  return ['workspace-transcript', data];
};

export function useWorkspaceTranscriptQuery(
  data: ITranscriptId,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {
  const query = useQueryWrapper({
    queryKey: buildQueryKey(data),
    queryFn: () => {
      return $api.workspaces.transcript.getWorkspaceTranscript({
        transcriptId: data.transcriptId,
      });
    },
    ...options,
  });

  return query;
}

export function useInvalidateWorkspaceTranscriptQuery() {
  const queryClient = useQueryClient();

  return useCallback((data: ITranscriptId) => {
    const queryKey = buildQueryKey(data);

    return queryClient.invalidateQueries(queryKey);
  }, [queryClient]);
}