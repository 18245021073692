/** @jsxImportSource @emotion/react */
import { useContext, useMemo } from 'react';
import Markdown, { type Options as MarkdownOptions } from 'react-markdown';
import remarkSubSuper from 'remark-supersub';
import remarkGfm from 'remark-gfm';
import remarkFlexibleMarkers from 'remark-flexible-markers';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ClickCitationContext } from '@/brand-insights/components/Chat/context';
import { getCitationNumberStyles } from './CitationNumber';

type Props = {
  components?: MarkdownOptions['components'];
  value: string;
};

export const ChatMarkdown = (props: Props) => {
  const components = useMarkdownComponents(props.components);

  return (
    <Markdown
      css={markdownCss}
      remarkPlugins={[remarkGfm, remarkSubSuper, remarkFlexibleMarkers]}
      components={components}>
      {props.value}
    </Markdown>
  );
};

export const BasicMarkdown = (props: { children: string }) => {
  const components = useMarkdownComponents();

  return (
    <Markdown
      css={markdownCss}
      components={components}>
      {props.children}
    </Markdown>
  );
};

function useMarkdownComponents(components?: MarkdownOptions['components']): MarkdownOptions['components'] {
  // TODO: move this out of main presentation component
  const onCitationClick = useContext(ClickCitationContext);

  return useMemo(() => ({
    p: props => (
      <StyledP>
        {props.children}
      </StyledP>
    ),
    a: props => (
      <StyledA
        href={props.href}
        target="_blank"
        rel="noopener">
        {props.children}
      </StyledA>
    ),
    ul: props => (
      <StyledUl>
        {props.children}
      </StyledUl>
    ),
    li: props => (
      <StyledLi>
        {props.children}
      </StyledLi>
    ),
    ol: props => (
      <StyledOL>
        {props.children}
      </StyledOL>
    ),
    strong: props => (
      <StyledStrong>
        {props.children}
      </StyledStrong>
    ),
    h1: props => (
      <StyledH1>
        {props.children}
      </StyledH1>
    ),
    h2: props => (
      <StyledH2>
        {props.children}
      </StyledH2>
    ),
    h3: props => (
      <StyledH3>
        {props.children}
      </StyledH3>
    ),
    h4: props => (
      <StyledH4>
        {props.children}
      </StyledH4>
    ),
    table: props => (
      <StyledTable>
        {props.children}
      </StyledTable>
    ),
    tr: props => (
      <StyledTr>
        {props.children}
      </StyledTr>
    ),
    td: props => (
      <StyledTd>
        {props.children}
      </StyledTd>
    ),
    th: props => (
      <StyledTh>
        {props.children}
      </StyledTh>
    ),
    sup: props => {
      const onClick = () => onCitationClick(props.children.toString());
      return (
        <StyledSup onClick={onClick}>
          {props.children}
        </StyledSup>
      );
    },
    mark: props => (
      <StyledMark>
        {props.children}
      </StyledMark>
    ),
    ...components,
  }), [components, onCitationClick]);
}

const markdownCss = css({
  whiteSpace: 'normal',
});

const StyledStrong = styled.strong(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
}));

const StyledP = styled.p({
  marginTop: 8,
  marginBottom: 0,

  '&:first-child': {
    marginTop: 0,
  },
});

const StyledOL = styled.ol({
  paddingInlineStart: 16,
});

const StyledA = styled.a(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',

  ':hover': {
    color: theme.palette.primary.main,
  },
}));

const StyledUl = styled.ul({
  paddingInlineStart: 16,
});

const StyledLi = styled.li(({ theme }) => ({
  marginBottom: 8,

  '&::last-child': {
    marginBottom: 0,
  },

  '::marker': {
    fontFamily: theme.fonts.semiBold,
  },
}));

const StyledH1 = styled.h1(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  fontSize: 18,
  marginTop: 22,
  marginBottom: 10,

  '&:first-child': {
    marginTop: 0,
  },
}));

const StyledH2 = styled.h2(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  fontSize: 16,
  marginTop: 22,
  marginBottom: 10,

  '&:first-child': {
    marginTop: 0,
  },
}));

const StyledH3 = styled.h3(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  fontSize: 16,
  marginTop: 18,
  marginBottom: 6,

  '&:first-child': {
    marginTop: 0,
  },
}));

const StyledH4 = styled.h4(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  fontSize: 16,
  marginTop: 18,
  marginBottom: 2,

  '&:first-child': {
    marginTop: 0,
  },
}));

const StyledTable = styled.table({
  borderSpacing: 0,
  borderCollapse: 'collapse',
  display: 'block',
  marginTop: 0,
  marginBottom: 16,
  width: 'max-content',
  maxWidth: '100%',
  overflow: 'auto',
});

const StyledTr = styled.tr({
  backgroundColor: '#ffffff',
  borderTop: `1px solid hsla(210, 18%, 87%, 1)`,

  ':nth-child(2n)': {
    backgroundColor: '#f6f8fa',
  },
});

const StyledTd = styled.td({
  padding: '6px 13px',
  border: `1px solid #d0d7de`,
});

const StyledTh = styled.th(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  padding: '6px 13px',
  border: `1px solid #d0d7de`,
}));

const StyledSup = styled.sup(({ theme }) => ({
  ...getCitationNumberStyles(theme),

  cursor: 'pointer',
  marginLeft: 2,
}));

const StyledMark = styled.mark({
  backgroundColor: 'yellow',
  borderRadius: 3,
});