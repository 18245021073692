import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import * as actions from '@actions';
import * as $logout from '@services/auth/logout';

type LogoutParams = {
  sideEffects: boolean;
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const query = useQueryClient();

  const handleLogout = useCallback((redirectUrl: string) => {
    dispatch(actions.logout({
      hasRedirect: !!redirectUrl,
      wasActionLogout: true,
    }));

    if (redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }

    Promise.all([
      query.cancelQueries(),
      // query.cancelMutations(),
    ])
      .then(() => query.clear());
  }, [dispatch, query]);

  const logout = async ({ sideEffects = true }: LogoutParams) => {
    if (sideEffects) {
      const { redirectUrl } = await $logout.logout();
      return handleLogout(redirectUrl);
    } else {
      const { redirectUrl } = await $logout.logoutUrl();
      return handleLogout(redirectUrl);
    }
  };

  return useCallback(logout, [handleLogout]);
};