import { useCallback, useContext, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { Constants } from '@/brand-insights/components/Theme';
import { Tooltip } from '@/brand-insights/components/presentation';
import type { Chat } from '@/brand-insights/types';
import { AssignCitationRefContext, OpenSnippetContext, OpenCitationContext, SetOpenCitationContext } from '@/brand-insights/components/Chat/context';
import { CitationNumber } from '@/brand-insights/components/presentation/CitationNumber';
import { toAlphabetic } from '@/brand-insights/utils';
import { ChatCitationType } from '@/brand-insights/enums';
import { MessageCitationMaybeLink } from './Citation.MaybeLink';
import { MessageCitationScore } from './Citation.Score';
import { MessageCitationSnippetGroupSelector } from './Citation.SnippetGroupSelector';
import { MessageCitationSnippet } from './Citation.Snippet';
import { getSnippetLink, getSnippetDisplayValue } from './utils';
import { QuantAnalysesCitation } from './Citation.QuantAnalyses';

type Props = {
  className?: string;
  item: Chat.Citation;
};

function buildCitationKey(item: Chat.Citation) {
  return `${item.type}-${item.id}`;
}

export const MessageCitation = ({ className, item }: Props) => {

  const ref = useRef<HTMLDivElement>(null);

  const [openSnippet, handleSnippetSelection] = useContext(OpenSnippetContext);
  const assignCitationRef = useContext(AssignCitationRefContext);

  const openCitation = useContext(OpenCitationContext);
  const setOpenCitation = useContext(SetOpenCitationContext);

  const highestScore = useMemo(() => {
    return Math.max(...item.snippets.map(snippet => snippet.score || 0));
  }, [item.snippets]);

  const onSnippetSelected = useCallback((snippetId: string) => () => {
    handleSnippetSelection([buildCitationKey(item), snippetId]);
  }, [handleSnippetSelection, item]);

  const citationKey = useMemo(() => buildCitationKey(item), [item]);

  const selectedSnippet = useMemo(() => {
    if (!openSnippet) return null;
    if (citationKey !== openSnippet[0]) return null;

    return item.snippets.find(snippet => snippet.id === openSnippet[1]);
  }, [citationKey, item.snippets, openSnippet]);

  const isCitationOpen = useMemo(() => {
    return openCitation === item.id || openSnippet?.[0] === citationKey;
  }, [openCitation, item.id, openSnippet, citationKey]);

  const setCitationRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const itemKey = buildCitationKey(item);
      assignCitationRef(itemKey, node);
      ref.current = node;
    }
  }, [assignCitationRef, item]);

  const toggleCitationOpen = useCallback(() => {
    const value = isCitationOpen ? null : item.id;
    setOpenCitation(value);
    if (!value) {
      handleSnippetSelection(null);
    }
  }, [handleSnippetSelection, isCitationOpen, item.id, setOpenCitation]);

  const renderOpenCitation = useCallback(() => {
    return null;
  }, [item.metadata, item.type, isCitationOpen]);

  const renderOpenSnippet = useCallback(() => {
    if (!selectedSnippet) return null;

    if (item.type === ChatCitationType.QuantAnalysis) {
      return (<QuantAnalysesCitation value={selectedSnippet.metadata as Chat.CitationSnippetMetadataQuantAnalysis} to={item.link} />);
    }

    return (
      <MessageCitationSnippet
        citationType={item.type}
        item={selectedSnippet}
        to={getSnippetLink(item, selectedSnippet)} />
    );
  }, [selectedSnippet, item]);

  return (
    <Root className={className} ref={setCitationRef}>
      <Wrap>
        <Header>
          {!!item.ordinal && <CitationOrdinal>{item.ordinal}</CitationOrdinal>}
          <Layers>
            <Layer1>
              <StyledLink to={item.link}>
                <Tooltip
                  title={item.title}
                  enterDelay={1000}
                  enterNextDelay={500}>
                  <Title>{item.title}</Title>
                </Tooltip>
              </StyledLink>
              {!!highestScore && <MessageCitationScore score={highestScore} />}
            </Layer1>
            <Layer2>
              {renderOpenCitation()}
              {!!item.snippets.length && (
                <SnippetGroupSelectorContainer>
                  {item.snippets.map((snippet, i) => {
                    const isSelected = selectedSnippet?.id === snippet.id;
                    const displayValue = getSnippetDisplayValue(snippet, item.type);
                    return (
                      <SnippetWrapper key={i}>
                        {!!snippet.ordinal && <SnippetOrdinal>{toAlphabetic(snippet.ordinal).toLocaleLowerCase()}</SnippetOrdinal>}
                        <MessageCitationSnippetGroupSelector
                          value={displayValue}
                          selected={isSelected}
                          onClick={onSnippetSelected(snippet.id)} />
                      </SnippetWrapper>
                    );
                  })}
                </SnippetGroupSelectorContainer>
              )}
            </Layer2>
          </Layers>
        </Header>
        {renderOpenSnippet()}
      </Wrap>
    </Root>
  );
};

const Root = styled.div`
  box-sizing: border-box;
`;

const Wrap = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div({
  padding: `12px 5px`,
  display: 'flex',
  alignItems: 'flex-start',
});

const Layer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Layer2 = styled.div({});

const Title = styled.div`
  font-family: ${props => props.theme.fonts.semiBold};
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SnippetGroupSelectorContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  margin-top: 10px;
`;

const SnippetWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledLink = styled(MessageCitationMaybeLink)`
  width: calc(100% - ${Constants.Message.CitationScoreWidth + 5}px);
`;

const Layers = styled.div({
  width: 'calc(100% - 30px)',
  display: 'flex',
  flexDirection: 'column',
});

const CitationOrdinal = styled(CitationNumber)({
  marginRight: 10,
});

const SnippetOrdinal = styled(CitationOrdinal)({
  marginRight: 5,
});