import { useState } from 'react';
import type { Chat } from '@/brand-insights/types';
import { LegendCollapseToggleContext, ChartRefContext, ChartInfoContext } from './context';

type Props = {
    children: React.ReactNode;
};

export const ChartContainer = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [chart, setChart] = useState<Chat.Chart | null>(null);

  return (
    <LegendCollapseToggleContext.Provider value={[open, setOpen]}>
      <ChartInfoContext.Provider value={[chart, setChart]}>
        <ChartRefContext.Provider value={[ref, setRef]}>
          {props.children}
        </ChartRefContext.Provider>
      </ChartInfoContext.Provider>
    </LegendCollapseToggleContext.Provider>
  );
};