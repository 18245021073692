import { useCallback, useContext, memo } from 'react';
import styled from '@emotion/styled';
import { MessageCitations } from '@/brand-insights/components/Chat.Message.Citations';
import { StarRating, useUpdateQueryRatingMutation, OpenQueryFeedbackModalContext } from '@/brand-insights/components/Chat.Feedback';
import { useCopyToClipboard } from '@/brand-insights/hooks';
import { getQueryResponseAsText } from '@/brand-insights/utils';
import { CapabilitiesContext } from '@/brand-insights/components/BrandInsights/context';
import type { Chat } from '@/brand-insights/types';
import { ChatResponsePane } from '@/brand-insights/components/Chat/ResponsePane';
import { ChatHintType } from '@/brand-insights/enums';
import { useShareChatModal } from '@/brand-insights/components/Chat.History/Modal.ShareChat';
import { ShareChatThread, ShareButton } from './Message.ShareChatThread';
import { CopyToClipboard, CopyButton } from './Message.CopyToClipboard';
import { DownloadButton } from './Message.Download';
import { GoToTraceUrl } from './Message.GoToTraceUrl';
import { RelatedQuestionsPane } from './QueryHints';
import { ActiveChatSessionContext, MessageCitationsToggleContext } from './context';
import { useChatPermissions } from './hooks';
import { ChartRefContext } from './context';

type Props = {
  item: Chat.Query;
  numCitations: number;
};

export const SystemUserMessageFooter = ({ item, numCitations }: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const chatPermissions = useChatPermissions();
  const capabilities = useContext(CapabilitiesContext);
  const [citationsOpen, toggleCitationsOpen] = useContext(MessageCitationsToggleContext);
  const [ref, setRef] = useContext(ChartRefContext);

  const { mutateAsync: updateQueryRating } = useUpdateQueryRatingMutation({
    chatInstanceId: chat.id,
    queryIdentifier: item.identifier,
  });

  const openFeedbackModal = useContext(OpenQueryFeedbackModalContext);

  const copyToClipboard = useCopyToClipboard();
  const [toggleShareModel, ShareModal] = useShareChatModal();

  const handleShareChatThread = useCallback(() => {
    toggleShareModel();
  }, [toggleShareModel]);

  const handleRatingClicked = useCallback((value: Chat.Rating) => {
    if (value <= 3) {
      return openFeedbackModal({
        queryIdentifier: item.identifier,
        chatInstanceId: chat.id,
        rating: value,
      });
    }

    return updateQueryRating(value);
  }, [
    item.identifier,
    updateQueryRating,
    openFeedbackModal,
    chat.id,
  ]);

  const handleCopy = useCallback(() => {
    copyToClipboard(getQueryResponseAsText(item.response));
  }, [copyToClipboard, item.response]);

  const view = {
    rating: chatPermissions.canModify,
    followUps: chatPermissions.canModify && !!item.suggestedFollowups.length,
    trace: capabilities.canViewTraces && !!item.langfuseTraceUrl,
  };

  // use ENUM
  return (
    <>
      <Layer2>
        <ActionsContainer>

          <ActionsLeft>
            <ShareButton
            onClick={handleShareChatThread} />
            <ShareModal chatInstance={chat} />

            <CopyButton
            onClick={handleCopy} />

            {item.response?.type === 'chart-quant-analyses-response' && ref &&
            <DownloadButton item={item} onClick={() => console.log('Download button clicked!')} />}

          </ActionsLeft>
          <ActionsRight>
            {view.rating && (
              <StyledStarRating
              value={item.rating}
              onChange={handleRatingClicked} />
            )}
            {view.trace && <StyledGoToTraceUrl url={item.langfuseTraceUrl} />}
          </ActionsRight>

        </ActionsContainer>
      </Layer2>
      {!!numCitations && (
        <StyledCitationsPane
          title={`Sources (${item.citations.length})`}
          open={citationsOpen}
          toggleOpen={toggleCitationsOpen}>
          <StyledMessageCitations
            items={item.citations} />
        </StyledCitationsPane>
      )}
      {view.followUps && (
        <StyledRelatedQuestions hints={item.suggestedFollowups.map(f => ({
          displayValue: f,
          type: ChatHintType.Text,
        }))} />
      )}
    </>
  );
};

type ContainerProps = {
  className?: string;
} & ChildrenProps;

export const SystemUserMessageFooterContainer = memo(({ className, children }: ContainerProps) => {
  return (
    <Root className={className}>
      <Wrap>
        {children}
      </Wrap>
    </Root>
  );
});

const Root = styled.div`
  margin-top: 15px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRelatedQuestions = styled(RelatedQuestionsPane)`
  margin-top: 15px;
`;

const StyledCitationsPane = styled(ChatResponsePane)`
  margin-top: 15px;
`;

const StyledMessageCitations = styled(MessageCitations)`
  margin-top: 10px;
`;

const StyledStarRating = styled(StarRating)`
  margin-left: 15px;
`;

const StyledGoToTraceUrl = styled(GoToTraceUrl)`
  margin-left: 10px;
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
  margin-left: 10px;
`;

const StyledShareChatThread = styled(ShareChatThread)`
  margin-left: 10px;
`;

const Layer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ActionsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const ActionsLeft = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

const ActionsRight = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});