import { useCallback, useContext, useMemo, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { usePopper } from 'react-popper';
import { WorkspaceTranscriptAccessContext, CallTrancriptWorkspaceActionsContext } from '@/containers/Transcript/context';
import { useTranscriptDownloadModal } from '@screens/ProjectSummary/TranscriptDownloadModal';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { MoreHorizontalAnchorSmall } from '@/presentation/Anchor';
import { useDownloader } from '@/utils';
import { useFetchTranscriptMedia } from '@/utils/api';
import { Portal } from '@/components/Portal';
import styles from './style/Transcript.ContextMenu.css';

type Props = ITranscriptId;

export const TranscriptContextMenuPopper = ({ transcriptId }: Props) => {

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const access = useContext(WorkspaceTranscriptAccessContext);
  const workspaceActions = useContext(CallTrancriptWorkspaceActionsContext);

  const { refetch: downloadMedia } = useFetchTranscriptMedia({
    transcriptId,
  }, {
    enabled: false,
    onSuccess: res => {
      download({ url: res.url });
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const download = useDownloader();

  const popupState = usePopupState({
    popupId: `transcript-actions-menu`,
    variant: 'popper',
  });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
    placement: 'bottom-start',
  });

  const [toggleDownloadTranscriptModal, DownloadTranscriptModal] = useTranscriptDownloadModal();

  const wrapWithClose = useCallback((fn: () => void) => () => {
    fn();
    popupState.close();
  }, [popupState]);

  return (
    <>
      <div
        {...bindToggle(popupState)}
        ref={setReferenceElement}>
        <MoreHorizontalAnchorSmall open={popupState.isOpen} />
      </div>
      {popupState.isOpen &&
        <Portal>
          <div
            ref={setPopperElement}
            className={styles.popper}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <PopperMenu>
                {access.canAccessTranscription &&
                  <PopperMenuItem onClick={wrapWithClose(toggleDownloadTranscriptModal)}>
                    Download Transcript
                  </PopperMenuItem>}
                {access.canAccessRecording &&
                  <PopperMenuItem onClick={wrapWithClose(downloadMedia)}>
                    Download Media
                  </PopperMenuItem>}
                {access.canManageAccess && (
                  <PopperMenuItem onClick={wrapWithClose(workspaceActions.manageAccess)}>
                    Manage Access
                  </PopperMenuItem>
                )}
                {access.canMove && (
                  <PopperMenuItem onClick={wrapWithClose(workspaceActions.rename)}>
                    Rename
                  </PopperMenuItem>
                )}
                {access.canMove && (
                  <PopperMenuItem onClick={wrapWithClose(workspaceActions.move)}>
                    Move
                  </PopperMenuItem>
                )}
              </PopperMenu>
            </ClickAwayListener>
          </div>
        </Portal>}
      <DownloadTranscriptModal transcriptId={transcriptId} />
    </>
  );
};