import { useState, useCallback } from 'react';
import { SelectedObjectsContext } from './context';

export const SelectedObjectsContainer = (props: ChildrenProps) => {
  const [selectedObjectIds, setSelectedObjectIds] = useState<number[]>([]);

  const toggleSelectedObject = useCallback((objectId: number) => {
    setSelectedObjectIds(old => {
      if (old.includes(objectId)) {
        return old.filter(x => x !== objectId);
      } else {
        return [...old, objectId];
      }
    });
  }, []);

  const clearSelectedObjects = useCallback(() => setSelectedObjectIds([]), []);

  return (
    <SelectedObjectsContext.Provider value={{ selectedObjectIds, toggleSelectedObject, clearSelectedObjects, setSelectedObjects: setSelectedObjectIds }}>
      {props.children}
    </SelectedObjectsContext.Provider>
  );
};