import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cx } from '@utils';
import { ChartRefContext } from '@/components/SurveyResponses/context';
import styles from './style/Tooltip.css';

type Props = {
  className?: string;
} & ChildrenProps;

export const TooltipWrapper = (props: Props) => {
  const chartRef = useContext(ChartRefContext);
  const mousePosition = useMousePosition();

  const requiresShift = useMemo(() => {
    const rect = chartRef.getBoundingClientRect();
    const x = mousePosition.x - rect.left;
    const graphWidth = chartRef.clientWidth;
    return x > graphWidth / 2;
  }, [chartRef, mousePosition]);

  const style = useMemo(() => {
    return {
      left: requiresShift ? '-50%' : '50%',
    };
  }, [requiresShift]);

  return (
    <div
      className={cx(styles.root, props.className)}
      style={style}>
      {props.children}
    </div>
  );
};

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  const updateMousePosition = useCallback((ev: MouseEvent) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  }, []);

  useEffect(() => {

    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, [updateMousePosition]);

  return mousePosition;
};