import * as Sentry from '@sentry/react';
import { history } from '@/services/history/history';

Sentry.init({
  dsn: 'https://974e745bea7849ac965e8693a3213ebc@s.sentimentglobal.com/2',
  enabled: process.env.ERROR_TRACKING as unknown as boolean,
  environment: process.env.VANCERY_ENV,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history,
    }),
    Sentry.dedupeIntegration(),
    Sentry.replayIntegration(),
  ],
  sampleRate: 1,
  tracesSampleRate: 0.5,
  // tracesSampleRate: process.env.VANCERY_ENV === 'production' ? 0.2 : 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: process.env.SENTRY_RELEASE,
});