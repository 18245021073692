import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Chat } from '@/brand-insights/types';
import {
  AssignCitationRefContext,
  OpenCitationContext,
  OpenSnippetContext,
  ClickCitationContext,
  MessageCitationsToggleContext,
  CitationsContext,
  SetOpenCitationContext,
} from './context';
import type { Context } from './interfaces';
import { ChartContainer } from './Message.Response.ChartContainer';

type Props = {
  className?: string;
  citations: Chat.Citation[];
} & ChildrenProps;

type RefMap = {
  [citationKey: string]: HTMLDivElement;
};

export const SystemMessageContainer = (props: Props) => {
  const [selectedSnippet, setSelectedSnippet] = useState<[string, string]>();
  const [citationsOpen, setCitationsOpen] = useState<boolean>(false);
  const [citationRefMap, setCitationRefMap] = useState<RefMap>({});
  const [openCitationKey, setOpenCitationKey] = useState<string>(null);

  const [pendingCitationToScrollTo, setPendingCitationToScrollTo] = useState<string>();

  const assignCitationRef = useCallback((citationKey: string, el: HTMLDivElement) => {
    setCitationRefMap(c => ({
      ...c,
      [citationKey]: el,
    }));
  }, []);

  const scrollToCitation = useCallback((citationKey: string) => {
    const ref = citationRefMap[citationKey];
    if (!ref) return false;
    ref.scrollIntoView({ behavior: 'smooth' });
    return true;
  }, [citationRefMap]);

  // When tab isn't open and citation is clicked, we need to wait for the ref to be available
  useEffect(() => {
    if (pendingCitationToScrollTo) {
      const scrolled = scrollToCitation(pendingCitationToScrollTo);
      if (scrolled) {
        setPendingCitationToScrollTo(undefined);
      }
    }
  }, [pendingCitationToScrollTo, scrollToCitation]);

  const toggleCitationsOpen = useCallback(() => {
    setCitationsOpen(o => !o);
    setCitationRefMap({});
  }, []);

  const handleSnippetSelection = useCallback((selected: [string, string]) => {
    setSelectedSnippet(s => {
      if (!s) return selected;
      if (s[0] === selected[0] && s[1] === selected[1]) return null;
      return selected;
    });
  }, []);

  const handleCitationClick = useCallback((value: string) => {

    const citationStr = value;
    const citationOrdinal = parseInt(citationStr);
    const citation = props.citations.find(f => f.ordinal === citationOrdinal);
    if (!citation) return console.error('Citation not found', citationOrdinal);

    const citationKey = buildCitationKey(citation);

    setCitationsOpen(true);
    setPendingCitationToScrollTo(citationKey);

    if (!citation.snippets.length) {
      setOpenCitationKey(citation.id);
    } else {
      const parsedCitationString = parseCitationString(value);

      if (!parsedCitationString) return console.error('Citation string not parsed', value);

      const citationOrdinal = parsedCitationString.citationOrdinal;
      const snippetOrdinal = parsedCitationString.snippetOrdinal;

      const citation = props.citations.find(f => f.ordinal === citationOrdinal);

      if (!citation) return console.error('Citation not found', citationOrdinal, snippetOrdinal);

      const snippet = citation.snippets?.find(f => f.ordinal === snippetOrdinal);

      if (!snippet) {
        console.error('Snippet not found', citationOrdinal, snippetOrdinal);
        setOpenCitationKey(citation.id);
        return;
      }

      setSelectedSnippet([citationKey, snippet?.id]);
    }
  }, [props.citations]);

  const citationsToggleCtx: Context.MessageCitationsToggle = useMemo(() => [citationsOpen, toggleCitationsOpen], [citationsOpen, toggleCitationsOpen]);
  const openSnippetCtx: Context.OpenSnippet = useMemo(() => [selectedSnippet, handleSnippetSelection], [selectedSnippet, handleSnippetSelection]);

  return (
    <AssignCitationRefContext.Provider value={assignCitationRef}>
      <MessageCitationsToggleContext.Provider value={citationsToggleCtx}>
        <OpenSnippetContext.Provider value={openSnippetCtx}>
          <ClickCitationContext.Provider value={handleCitationClick}>
            <CitationsContext.Provider value={props.citations}>
              <OpenCitationContext.Provider value={openCitationKey}>
                <SetOpenCitationContext.Provider value={setOpenCitationKey}>
                  <ChartContainer>
                    {props.children}
                  </ChartContainer>
                </SetOpenCitationContext.Provider>
              </OpenCitationContext.Provider>
            </CitationsContext.Provider>
          </ClickCitationContext.Provider>
        </OpenSnippetContext.Provider>
      </MessageCitationsToggleContext.Provider>
    </AssignCitationRefContext.Provider>
  );
};

function buildCitationKey(item: Chat.Citation) {
  return `${item.type}-${item.id}`;
}

function parseCitationString(value: string) {
  const match = value.match(/^(\d+)([a-z]*)$/i);
  if (match) {
    const snippetOrdinalStr = match[2];

    let snippetOrdinal = 0;
    for (const char of snippetOrdinalStr) {
      snippetOrdinal = snippetOrdinal * 26 + char.charCodeAt(0) - 96;
    }

    return {
      citationOrdinal: parseInt(match[1], 10),
      snippetOrdinal,
    };
  }
  return null;
}