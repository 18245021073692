import { memo, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { trunc } from '@/utils';
import { ChatHintType, ChatActionType } from '@/brand-insights/enums';
import { MessageQueryHintContainer, TextQueryHint } from '../presentation';
import { SubmitActionContext } from './context';
import { QueryFilesSuggestedAction } from './Message.SuggestedActions.Files';

type Props = {
  data: Chat.QuantData;
};

const formatQuery = (query: string) => {
  const lowerCaseQuery = query.charAt(0).toLowerCase() + query.slice(1);
  return lowerCaseQuery;
};

export const FindQuantAnalysesResponse = memo((props: Props) => {
  const ctx = useContext(SubmitActionContext);

  const handleClick = useCallback((quant: Chat.Quant) => {
    const fullQuery = `Based on the **${trunc(quant.title, 150)}** analysis, ${formatQuery(props.data.query)}`;

    return ctx({
      type: ChatActionType.ChartQuantAnalyses,
      quantItemId: quant.quantItemId,
      quantId: quant.quantId,
      query: props.data.query,
      projectId: props.data.projectId,
    }, fullQuery);
  }, [ctx, props.data.projectId, props.data.query]);

  if (!props.data.quants.length) {
    return (
      <div>
        {"I'm sorry, I cannot find any relevant quant analyses."}
      </div>
    );
  }

  return (
    <div>
      <div>
        {'The following analyses were found that may be relevant. Select one to answer your question or try a global search of files.'}
      </div>
      <br />
      <b> Analyses </b>
      <br /><br />
      <MessageQueryHintContainer>
        {props.data.quants.map(quant => {
          const trimmed = trunc(quant.title, 150);
          const hint: Chat.QueryHint.Item = {
            displayValue: trimmed,
            type: ChatHintType.Text,
          };
          return (
            <TextQueryHint
              key={quant.quantItemId}
              hint={hint}
              onClick={() => { handleClick(quant); }} />
          );
        })}
      </MessageQueryHintContainer>

      <br />

      <QueryFilesSuggestedAction item={null} query={props.data.query} />
    </div>
  );
});

