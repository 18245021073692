import { ErrorBoundary } from '@sentry/react';
import { ErrorMessage } from '@/screens/ErrorMessage';

type Props = {
  children: React.ReactNode;
};

export const SentryErrorBoundary = (props: Props) => {
  return (
    <ErrorBoundary fallback={ErrorMessage}>
      {props.children}
    </ErrorBoundary>
  );
};