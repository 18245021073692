import { useContext, useMemo } from 'react';
import { MedicalBackgroundAdditionalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { useIsInternalUser } from '@/containers/Store';

export const useBackgroundAdditionalExpertiseValidation = () => {
  const additionalexpertise = useContext(MedicalBackgroundAdditionalExpertiseContext);

  const isInternalUser = useIsInternalUser();

  const validation = useMemo(() => {
    const errors = {
      boardEligibility: !isInternalUser && !additionalexpertise.form.boardEligibility.answer,
      therapeuticAreas: !isInternalUser && !(additionalexpertise.form.therapeuticAreas.map(x => x.id).length === 3),
    };

    return {
      errors,
    };
  }, [additionalexpertise.form.boardEligibility.answer, additionalexpertise.form.therapeuticAreas, isInternalUser]);

  return validation;
};