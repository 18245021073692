import { AppReadyStateContainer } from '@containers/AppReadyState';
import { AppStateEffectContainer } from '@containers/AppStateEffect';
import { ConferenceContainer } from '@containers/Conference/ConferenceContainer';
import { PersistentURLStateContainer } from '@containers/PersistentURLState';
import { QueryDownloaderContainer } from '@containers/QueryDownloader';
import { ScrollRestorationContainer } from '@containers/ScrollRestoration';
import { PastSearchesContainer } from '@containers/WorkspaceGlobalSearch/PastSearchesContainer';
import { ZendeskScriptContainer } from '@containers/Zendesk';
import { SearchContainer } from '@containers/Search';
import { UTMTrackingContainer } from '@containers/UTMTracking';
import UniversalSearch from './UniversalSearch';
import { BrowserHistory } from './BrowserHistory';
import { RootVendors } from './RootVendors';

type Props = ChildrenProps;

export const AppSubscriber = (props: Props) => {
  return (
    <RootVendors>
      <AppReadyStateContainer>
        <BrowserHistory>
          <ScrollRestorationContainer>
            <QueryDownloaderContainer>
              <PersistentURLStateContainer>
                <ZendeskScriptContainer>
                  <AppStateEffectContainer>
                    <PastSearchesContainer>
                      <SearchContainer>
                        <UniversalSearch>
                          <UTMTrackingContainer>
                            <ConferenceContainer>
                              {props.children}
                            </ConferenceContainer>
                          </UTMTrackingContainer>
                        </UniversalSearch>
                      </SearchContainer>
                    </PastSearchesContainer>
                  </AppStateEffectContainer>
                </ZendeskScriptContainer>
              </PersistentURLStateContainer>
            </QueryDownloaderContainer>
          </ScrollRestorationContainer>
        </BrowserHistory>
      </AppReadyStateContainer>
    </RootVendors>
  );
};

export default AppSubscriber;