import type { DependencyList } from 'react';
import { useEffect } from 'react';
import type { AsyncFn, AsyncState } from '@utils/hooks/useAsyncStateLazy';
import {
  useAsyncStateLazy,
} from '@utils/hooks/useAsyncStateLazy';

const defaultState = {
  loading: true,
};

const useAsyncState: typeof useAsyncStateLazy = <T = unknown, U = unknown>(
  fn: AsyncFn<T, U>,
  dependencies: DependencyList = [],
  initialState: AsyncState<T> = defaultState,
): [AsyncState<T>, AsyncFn<T, U>] => {
  const [state, cb] = useAsyncStateLazy(fn, dependencies, initialState);

  useEffect(() => {
    cb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, cb];
};

export { useAsyncState };
export default useAsyncState;